<template>
  <div class="technology-area-three pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="section-title">
            <span class="sp-color1">Technology Index</span>
            <h2>We Deliver Our Best EdTech Solution With The Goal of Trusting</h2>
            <p>
              A technology index refers to a measurement or assessment of the technological components and infrastructure used in an elearning system or platform. This index helps evaluate the effectiveness, efficiency, and overall quality of the technology employed in elearning. Here are some key components that are often considered when creating a technology index for elearning:
            </p>
            <router-link
              to="/contact"
              class="default-btn btn-bg-two border-radius-50 text-center"
            >
              Get A Quote
            </router-link>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="row justify-content-center">
            <div
              class="col-lg-4 col-6 col-md-4"
              v-for="technology in technologys"
              :key="technology.id"
            >
              <div class="technology-card technology-card-color2">
                <i :class="technology.icon"></i>
                <h3>{{ technology.title }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnologyIndex",
  data() {
    return {
      technologys: [
        {
          id: 1,
          icon: "flaticon-android",
          title: "Animation",
        },
        {
          id: 2,
          icon: "flaticon-website",
          title: "Web",
        },
        {
          id: 3,
          icon: "flaticon-apple",
          title: "UI & UX",
        },
        {
          id: 4,
          icon: "flaticon-television",
          title: "LMS",
        },
        {
          id: 5,
          icon: "flaticon-smartwatch",
          title: "Games",
        },
        {
          id: 6,
          icon: "flaticon-cyber-security",
          title: "Mobile",
        },
      ],
    };
  },
};
</script>