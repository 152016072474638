<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <MainBanner />
  <SoftwareInnovation />
  <OurServices />
  <OurWorkingProcess />
  <BuildYourWebsite />
  <NumbersAreTalking />
  <CallUs callUsImage="call-us/call-us-img1.png" />
  <CaseStudy />
  <TechnologyIndex className="technology-area" />
  <BrandLogo className="brand-area" />
  <OurClients />
  <LatestBlog />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import MainBanner from "../HomeOne/MainBanner";
import SoftwareInnovation from "../HomeOne/SoftwareInnovation";
import OurServices from "../HomeOne/OurServices";
import OurWorkingProcess from "../HomeOne/OurWorkingProcess";
import BuildYourWebsite from "../HomeOne/BuildYourWebsite";
import NumbersAreTalking from "../Common/NumbersAreTalking";
import CallUs from "../Common/CallUs";
import CaseStudy from "../Common/CaseStudy";
import TechnologyIndex from "../Common/TechnologyIndex";
import BrandLogo from "../Common/BrandLogo";
import OurClients from "../Common/OurClients";
import LatestBlog from "../Common/LatestBlog";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "HomeOnePage",
  components: {
    TopHeader,
    MainNavbar,
    MainBanner,
    SoftwareInnovation,
    OurServices,
    OurWorkingProcess,
    BuildYourWebsite,
    NumbersAreTalking,
    CallUs,
    CaseStudy,
    TechnologyIndex,
    BrandLogo,
    OurClients,
    LatestBlog,
    MainFooter,
  },
};
</script>