<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <PageTitle pageTitle="Flash to HTML5 Conversion" mainTitle="Flash to HTML5 Conversion" />
  <FlashTHTMLDetails />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import PageTitle from "../Common/PageTitle";
import FlashTHTMLDetails from "../FlashTHTMLDetails/FlashTHTMLDetails";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "FlashTHTMLPage",
  components: {
    TopHeader,
    MainNavbar,
    PageTitle,
    FlashTHTMLDetails,
    MainFooter,
  },
};
</script>