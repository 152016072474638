<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <MainBanner />
  <OurWorkingProcess />
  <AboutUs />
  <OurServices />
  <OurClients />
  <NumbersAreTalking class="pt-100" />
  <CaseStudy />
  <TechnologyIndex />
  <LatestBlog />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import MainBanner from "../HomeFive/MainBanner";
import OurWorkingProcess from "../HomeFive/OurWorkingProcess";
import AboutUs from "../HomeFive/AboutUs";
import OurServices from "../HomeFive/OurServices";
import OurClients from "../HomeFive/OurClients";
import NumbersAreTalking from "../Common/NumbersAreTalking";
import CaseStudy from "../HomeFive/CaseStudy";
import TechnologyIndex from "../HomeFive/TechnologyIndex";
import LatestBlog from "../HomeFive/LatestBlog";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "HomeFivePage",
  components: {
    TopHeader,
    MainNavbar,
    MainBanner,
    OurWorkingProcess,
    AboutUs,
    OurServices,
    OurClients,
    NumbersAreTalking,
    CaseStudy,
    TechnologyIndex,
    LatestBlog,
    MainFooter,
  },
};
</script>