<template>
  <div class="choose-area pt-100 pb-70">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6">
          <div class="choose-content mr-20">
            <div class="section-title">
              <span class="sp-color1">Why Choose Us</span>
              <h2>We Provide Truly Prominent EdTech and IT Solutions.</h2>
              <p>
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio
                sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan
                ipsum auctor a ornare odio.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-6">
                <div class="choose-content-card">
                  <div class="content">
                    <i class="flaticon-practice"></i>
                    <h3>Experience</h3>
                  </div>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-6">
                <div class="choose-content-card">
                  <div class="content">
                    <i class="flaticon-help"></i>
                    <h3>Quick Support</h3>
                  </div>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="choose-img">
            <img src="../../assets/images/choose-img.jpg" alt="Images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUs",
};
</script>