<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <PageTitle pageTitle="Web Development" mainTitle="Web Development" />
  <WebDevelopmentDetails />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import PageTitle from "../Common/PageTitle";
import WebDevelopmentDetails from "../WebDevelopDetails/WebDevelopDetails";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "WebDevelopmentPage",
  components: {
    TopHeader,
    MainNavbar,
    PageTitle,
    WebDevelopmentDetails,
    MainFooter,
  },
};
</script>