<template>
  <div class="preloader">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
};
</script>

<style lang="scss" scoped>
.preloader-area {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  text-align: center;
  background-color: var(--whiteColor);

  .loader {
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    top: 50%;
    left: 0;
  }
  .waviy {
    margin-top: 20px;
    position: relative;
    text-align: center;
    -webkit-box-reflect: below -42px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
    font: {
      size: 50px;
      weight: 700;
    }
    span {
      position: relative;
      animation-delay: 0.1s;
      display: inline-block;
      color: var(--blackColor);
      animation: waviy 1s infinite;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.3s;
      }
      &:nth-child(4) {
        animation-delay: 0.4s;
      }
    }
  }
}
@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}
</style>