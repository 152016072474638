<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <PageTitle pageTitle="Staff Augmentation" mainTitle="Staff Augmentation" />
  <StaffAugDetails />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import PageTitle from "../Common/PageTitle";
import StaffAugDetails from "../StaffAugDetails/StaffAugDetails";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "StaffAugPage",
  components: {
    TopHeader,
    MainNavbar,
    PageTitle,
    StaffAugDetails,
    MainFooter,
  },
};
</script>