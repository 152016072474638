<template>
  <div :class="['ptb-100', className]">
    <div class="container">
      <div class="talk-content text-center">
        <div class="section-title text-center">
          <span class="sp-color1">Let's Talk</span>
          <h2>We Are Adding Kinds of It EdTech Services That You Learn and Grow Success</h2>
        </div>
        <router-link to="/contact" class="default-btn border-radius-5">
          Contact Us
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LetsTalk",
  props: ["className"],
};
</script>