<template>
  <div class="error-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="error-content">
          <img src="../../assets/images/404-error.jpg" alt="Image" />
          <h3>Oops! Page Not Found</h3>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <router-link to="/" class="default-btn btn-bg-two">
            Return To Home Page
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
};
</script>