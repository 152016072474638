<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/programming.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>User Interfaces & User Experiences</h2>
              <p>                
                At Lucreskill, we understand UI/UX design has the potential to make or break a brand, reputation, and business at large. Our extraordinarily skilled developers endorse the significance of impactful UI/UX design for any business as it enhances customer satisfaction and boosts business growth. Throughout our UI/UX development cycle, the demands of our clients and their customers remain the heart and soul of our design process.
              </p>
              <h4>We Cross Limits and Go Heights to Craft Phenomenal Experiences for Our Clients</h4>
              <p>Our masterly designers specialize in developing engaging products, ensuring a well-defined design process, compliance with timelines, and agreement with business needs and demands. The creative and strategic ideas of our tech gurus help our clients achieve business goals.</p>
              <h4>We Bring Brands to Life with Intuitive UI / UX Design</h4>
              <p>Our UI/UX development and design process are solely based on pragmatic ideas emanated from an extensive understanding of the market of our clients and their customers. Lucreskill 's UI/UX team always works in partnership with clients to bring their vision to life.</p>
              <p>When clients engage Lucreskill Technologies, our seasoned developers and specialists will:</p>
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Research, analyse and understand the requirements, expectations, needs, and demands of clients and their customers.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Work closely with clients to plan visually stimulating and user-friendly UI/UX visual design.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Accomplish stunning visual designs to the satisfaction of the clients.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Fulfil the purpose of the project at every level and present delightful overall experiences.
                    </li>
                     <li>
                      <i class="bx bxs-check-circle"></i>Deliver high quality products within the deadline.
                    </li>
                  </ul>
                </div>               
              </div>
            </div>
            <div class="case-article-content">
              <h2>Web Design</h2>
              <p>                
                Having a great website that acts as a digital engine that converts all inquiries into customers is the key to any successful business. If you are looking to own a website that is easy to use, well- designed, responsive, simple, elegant, search engine optimized and offer clear calls to action and deliver superb performance, then look no further – We are Lucreskill, the leading website development company in India.</p>
               <br />
               <h2>Dynamic Website Design</h2>
               <p>Lucreskill the best dynamic website design company where imaginations meet reality delivering an impressive website. A business can experience a roller coaster ride in the market fluctuations. A company can build its trust towards customers by constructing a stunning online presence through dynamic websites. In the modern digital era based on viewers impression, visitors time, their choice of languages, a dynamic website design company can make your web pages always new and revived.</p>
               <br />
               <h2>Static Website Design</h2>
               <p>Nowadays lots of enterprises are struggling to come up with a website that really showcases their business in a better way.</p>
               <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <ul class="case-article-list case-article-rs case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>The content in the website will be viewed by every visitor and customer unless it changes by the webmasters.
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Loading is faster and static designs always keep the top position in search Results.
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Every design and structure of the website design from the customer perspective
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Website with essential content and completely SEO friendly design
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>It's simple coding process helps to restore easily
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Fast Loading speed and High performance
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>User friendly and easy to navigate
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Easy to build and Maintain
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Budget Friendly
                      </li>
                    </ul>
                  </div>
              </div>
            </div>
            <div class="case-article-content">
              <h2>E-Commerce Website Development</h2>
              <p>                
                Consumer purchasing behaviour is sophisticated and more global today, increasing the specificity of their needs and demands. They have more access to information and choices that increase pressure on businesses to deliver more value. Consumers expect more robust experiences and greater convenience.</p>
                <p>Whether developing E-Commerce websites on platforms like Woo-Commerce and Magento or creating highly effective, fully functional, customized sites – you name it, we will get it done! We build feature-rich & eye-catching E-Commerce websites that help our clients deliver online experiences to consumers that outperform user expectations and bring back clear business results & outcomes, while retaining users.</p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="case-article-list case-article-rs case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>Fast page load time
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Engaging aesthetics
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>SEO optimized pages
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Secure payment network
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Easy navigation
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <ul class="case-article-list case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>Mobile phone compatibility
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Efficient shipping cart
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Clear descriptions
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Seamless checkout options
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Effective search
                      </li>                    
                    </ul>
                  </div>
                  <p>We specialize in developing E-Commerce shopping sites that provide a fascinating online shopping experience that makes people come back again and again. We ensure that every feature on your shopping site is (charmingly) guiding the customer through the buying process – and make it a joy.</p>
                  <br />
                  <h2>Shopify E-Commerce Platform</h2>
                  <p>Are you thinking about designing a website for your business on the Shopify platform? We can help you tap into all the features offered by the e-commerce platform from marketing, shipping to payments. Let me walk you through the most important points to keep in mind while designing your website.</p>              
                </div>
            </div>
            <div class="case-article-content">
              <h2>Prefered Web Technologies</h2>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="case-article-list case-article-rs case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>React.js
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Vue.js
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Angular
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Java
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>PHP/MySQL
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <ul class="case-article-list case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>ASP.NET MVC
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Django
                      </li>  
                      <li>
                        <i class="bx bxs-check-circle"></i>Laravel
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Drupal 
                      </li>                
                      <li>
                        <i class="bx bxs-check-circle"></i>WordPress 
                      </li>    
                    </ul>
                  </div>
              </div>
            </div>
           
            <div class="case-article-content">
              <h2>Prefered Cloud Technologies</h2>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="case-article-list case-article-rs case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>Azure
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Google Cloud Platform
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <ul class="case-article-list case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>Amazon Web Service
                      </li>
                    </ul>
                  </div>
              </div>
            </div>

            <div class="case-play-btn">
              <a
                class="case-play"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>

            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucerskill is often at the forefront of adopting new learning technologies and methodologies, 
                ensuring that your organization benefits from the latest advancements by assuring commitment
                to keep content current and providing ongoing technical and customer support. 
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <p>
                Keeping a well-maintained website attracts businesses and enables them to engage and retain customers for a longer period of time.
                Hence, regular monitoring of your website is crucial to running your online business seamlessly.
              </p>
            </div>
            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                We provide the web development checklist will help you to build feature rich websites efficiently and help you to understand how we streamline the process and commit our clients to the best service they will ever get.</p>
                <p>To understand your web application development requirements, our team of experienced designers, developers, and QA specialists will build contemporary web based solutions for your business.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <SideBar />
        </div>       
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "AnimationVideoDev",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>