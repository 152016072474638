<template>
  <div class="banner-six">
    <div class="container">
      <div class="banner-six-content">
        <h1>We Provide Truly Promising Ed Tech Services</h1>
        <p>
          Aenean Sollicitudin, Lorem quis Bibendum auctor, nisi elit consequat
          ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh
          vulputate cursus a sit amet
        </p>
        <div class="banner-btn">
          <router-link
            to="/about"
            class="default-btn btn-bg-two border-radius-5"
          >
            Learn More
            <i class="bx bx-chevron-right"></i>
          </router-link>
        </div>
      </div>
    </div>
    <div class="banner-six-shape">
      <img src="../../assets/images/shape/shape4.png" alt="Banner" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>