<template>
  <div class="blog-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Latest Blog</span>
        <h2>Let’s Check Some Latest Blog</h2>
      </div>

      <div class="row pt-45 justify-content-center">
        <div class="col-lg-4 col-md-6" v-for="blog in blogs" :key="blog.id">
          <div class="blog-item">
            <div class="blog-img">
              <router-link to="/blog-details">
                <img :src="blog.image" alt="Blog Images" />
              </router-link>
              <router-link to="/blog" class="blog-tag">
                {{ blog.tag }}
              </router-link>
            </div>
            <div class="content">
              <ul>
                <li>{{ blog.date }}</li>
                <li>
                  <router-link to="/blog">
                    <i class="bx bxs-user"></i>
                    By {{ blog.user }}
                  </router-link>
                </li>
              </ul>
              <h3>
                <router-link to="/blog-details">{{ blog.title }}</router-link>
              </h3>
              <p>{{ blog.desc }}</p>
              <router-link to="/blog-details" class="read-btn">
                Read More
                <i class="bx bx-chevron-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LatestBlog",
  data() {
    return {
      blogs: [
        {
          id: 1,
          image: require("../../assets/images/blog/blog-img7.jpg"),
          date: "13 March",
          user: "Admin",
          tag: "Business",
          title: "Product Idea Solution for New Generation",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
        {
          id: 2,
          image: require("../../assets/images/blog/blog-img8.jpg"),
          date: "12 March",
          user: "Admin",
          tag: "Invention",
          title: "New Device Invention for Digital Platform",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
        {
          id: 3,
          image: require("../../assets/images/blog/blog-img9.jpg"),
          date: "11 March",
          user: "Admin",
          tag: "Achive",
          title: "Business Strategy Make His Goal Acheive",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
      ],
    };
  },
};
</script>