<template>
  <div class="counter-area pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Numbers Are Talking</span>
        <h2>Let’s Check Our Business Growth and Success Story</h2>
        <p>
          Lucreskill Tech has been working for EdTech solutions across the globe to help them build, deploy, and streamline their digital infrastructure.
          We hold the belief that every action we take and every solution we provide must be tailored to meet the unique requirements of each of our clients. We consider our clients as our partners and work closely with them for success.
        </p>
      </div>
      <div class="row pt-45">
        <div
          class="col-lg-3 col-6 col-md-3"
          v-for="count in counter"
          :key="count.id"
        >
          <div class="counter-another-content">
            <i :class="count.icon"></i>
            <h3>{{ count.number }}+</h3>
            <span>{{ count.title }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="counter-shape">
      <div class="shape1">
        <img src="../../assets/images/shape/shape1.png" alt="Images" />
      </div>
      <div class="shape2">
        <img src="../../assets/images/shape/shape2.png" alt="Images" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumbersAreTalking",
  data() {
    return {
      counter: [
        {
          id: 1,
          icon: "flaticon-consulting-1",
          number: "9000",
          title: "Hours of Videos & Animations",
        },
        {
          id: 2,
          icon: "flaticon-web-development",
          number: "45",
          title: "IT Consulting",
        },
        {
          id: 3,
          icon: "flaticon-startup",
          number: "550",
          title: "Fully Launched",
        },
        {
          id: 4,
          icon: "flaticon-tick",
          number: "500",
          title: "Project Completed",
        },
      ],
    };
  },
};
</script>