<template>
  <TopHeader cFluid="container-fluid" className="container-max" />
  <MainNavbar
    cFluid="container-fluid"
    className="container-max"
    navSideMt="nav-side-mt"
  />
  <MainBanner />
  <AboutUs />
  <ITSecurity />
  <OurServices className="services-area-three" />
  <BuildYourWebsite />
  <CaseStudy class="pt-0" />
  <LetsTalk className="talk-area" />
  <TechnologyIndex className="technology-area-two" />
  <BrandLogo className="brand-area-two" />
  <OurClients />
  <LatestBlog />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import MainBanner from "../HomeThree/MainBanner";
import AboutUs from "../HomeThree/AboutUs";
import ITSecurity from "../HomeThree/ITSecurity";
import OurServices from "../Common/OurServices";
import BuildYourWebsite from "../HomeThree/BuildYourWebsite";
import CaseStudy from "../Common/CaseStudy";
import LetsTalk from "../Common/LetsTalk";
import TechnologyIndex from "../Common/TechnologyIndex";
import BrandLogo from "../Common/BrandLogo";
import OurClients from "../Common/OurClients";
import LatestBlog from "../Common/LatestBlog";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "HomeThreePage",
  components: {
    TopHeader,
    MainNavbar,
    MainBanner,
    AboutUs,
    ITSecurity,
    OurServices,
    BuildYourWebsite,
    CaseStudy,
    LetsTalk,
    TechnologyIndex,
    BrandLogo,
    OurClients,
    LatestBlog,
    MainFooter,
  },
};
</script>