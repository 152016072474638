<template>
  <div class="choose-area pt-100 pb-70">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6">
          <div class="choose-content mr-20">
            <div class="section-title">
              <span class="sp-color1">Why Choose Us</span>
              <h2>We Provide Truly Prominent ED Tech Solutions.</h2>
              <p>
                Lucreskill Tech is an integrative and interactive eLearning solution provider, having development centers in Pune and New Delhi, India, established in 2010. We create and customize learning methodologies and techniques, and combine them with the best practices, to yield enhanced digital learning experiences for millennial learners.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-6">
                <div class="choose-content-card">
                  <div class="content">
                    <i class="flaticon-practice"></i>
                    <h3>Experience</h3>
                  </div>
                  <p>
                    With 1000 + projects over a decade of our existence, we have sustained an annual growth rate of 100%, establishing our potential as a dynamic organization.
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-6">
                <div class="choose-content-card">
                  <div class="content">
                    <i class="flaticon-help"></i>
                    <h3>Quick Support</h3>
                  </div>
                  <p>
                    Lucreskill provides On-demand, Remote Quick IT Support that is safe, reliable and secure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="choose-img">
            <img src="../../assets/images/choose-img.jpg" alt="Images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUs",
};
</script>