<template>
  <div class="build-area-two pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Case Study</span>
        <h2>Let's Build Your Website!</h2>
      </div>

      <div class="row pt-45 justify-content-center">
        <div class="col-lg-4 col-md-6" v-for="item in items" :key="item.id">
          <div class="build-item">
            <router-link to="/case-study-details">
              <img :src="item.image" alt="Images" />
            </router-link>
            <div class="content">
              <h3>
                <!--<router-link to="/case-study-details">{{
                  item.title
                }}</router-link>
                // commented to hide the hyperlinks
              -->
                {{
                  item.title
                }}
              </h3>
              <ul>
                <li v-for="tag in item.tags" :key="tag.id">
                  <router-link to="/case-study">{{ tag.tag }}</router-link>
                </li>
              </ul>
              <router-link to="/case-study-details" class="more-btn">
                <i class="bx bx-right-arrow-alt"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStudy",
  data() {
    return {
      items: [
        {
          id: 1,
          image: require("../../assets/images/build/build-img4.jpg"),
          title: "Web Development",
          tags: [
            {
              id: 1,
              tag: "Web",
            },
            {
              id: 2,
              tag: "Development",
            },
          ],
        },
        {
          id: 2,
          image: require("../../assets/images/build/build-img5.jpg"),
          title: "Social Media App",
          tags: [
            {
              id: 1,
              tag: "Social",
            },
            {
              id: 2,
              tag: "App",
            },
          ],
        },
        {
          id: 3,
          image: require("../../assets/images/build/build-img6.jpg"),
          title: "UI/UX Research",
          tags: [
            {
              id: 1,
              tag: "UI/UX",
            },
            {
              id: 2,
              tag: "Research",
            },
          ],
        },
      ],
    };
  },
};
</script>