<template>
  <swiper
    :slidesPerView="'auto'"
    :grabCursor="true"
    :freeMode="true"
    :spaceBetween="25"
    :speed="11000"
    :a11y="false"
    :loop="true"
    :autoplay="{
      delay: 0.5,
      disableOnInteraction: false,
    }"
    :breakpoints="{
      0: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 3,
      },
    }"
    :modules="modules"
    class="banner-sub-slide"
  >
    <swiper-slide class="text-center" v-for="item in items" :key="item.id">
      <div class="banner-sub-item">
        <img :src="item.image" alt="Images" />
        <div class="content">
          <h3>{{ item.number }}{{ item.symbol }}</h3>
          <span>{{ item.title }}</span>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";

export default {
  name: "BannerSubItem",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          image: require("../../assets/images/home-three/home-three-sub1.jpg"),
          number: "45",
          symbol: "",
          title: "Satisfied Clients",
        },
        {
          id: 2,
          image: require("../../assets/images/home-three/home-three-sub2.jpg"),
          number: "10",
          symbol: "+",
          title: "Country Worldwide",
        },
        {
          id: 3,
          image: require("../../assets/images/home-three/home-three-sub3.jpg"),
          number: "500",
          symbol: "+",
          title: "Project Completed",
        },
        {
          id: 1,
          image: require("../../assets/images/home-three/home-three-sub1.jpg"),
          number: "50",
          symbol: "",
          title: "Satisfied Clients",
        },
        {
          id: 2,
          image: require("../../assets/images/home-three/home-three-sub2.jpg"),
          number: "10",
          symbol: "+",
          title: "Country Worldwide",
        },
        {
          id: 3,
          image: require("../../assets/images/home-three/home-three-sub3.jpg"),
          number: "500",
          symbol: "+",
          title: "Project Completed",
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>