<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <MainBanner />
  <WhyChooseUs />
  <OurServices className="services-area-three" />
  <LetsTalk className="talk-area-two" />
  <OurWorkingProcess />
  <OurClients class="clients-content-color" />
  <CaseStudy />
  <TechnologyIndex />
  <LatestBlog />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import MainBanner from "../HomeFour/MainBanner";
import WhyChooseUs from "../HomeFour/WhyChooseUs";
import OurServices from "../Common/OurServices";
import LetsTalk from "../Common/LetsTalk";
import OurWorkingProcess from "../HomeFour/OurWorkingProcess";
import OurClients from "../Common/OurClients";
import CaseStudy from "../HomeFour/CaseStudy";
import TechnologyIndex from "../HomeFour/TechnologyIndex";
import LatestBlog from "../HomeFour/LatestBlog";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "HomeFourPage",
  components: {
    TopHeader,
    MainNavbar,
    MainBanner,
    WhyChooseUs,
    OurServices,
    LetsTalk,
    OurWorkingProcess,
    OurClients,
    CaseStudy,
    TechnologyIndex,
    LatestBlog,
    MainFooter,
  },
};
</script>