<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <PageTitle pageTitle="Contact Us" mainTitle="Contact Us" />
  <ContactForm />
  <ContactMap />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import PageTitle from "../Common/PageTitle";
import ContactForm from "../Contact/ContactForm";
import ContactMap from "../Contact/ContactMap";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "ContactPage",
  components: {
    TopHeader,
    MainNavbar,
    PageTitle,
    ContactForm,
    ContactMap,
    MainFooter,
  },
};
</script>