<template>
  <div :class="['pt-100 pb-70', className]">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color1">Technology Index</span>
        <h2>We Deliver Our Best Solution With The Goal of Trusting</h2>
      </div>

      <div class="row pt-45 justify-content-center">
        <div
          class="col-lg-2 col-6"
          v-for="technology in technologys"
          :key="technology.id"
        >
          <div class="technology-card">
            <i :class="technology.icon"></i>
            <h3>{{ technology.title }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnologyIndex",
  props: ["className"],
  data() {
    return {
      technologys: [
        {
          id: 1,
          icon: "flaticon-android",
          title: "Android",
        },
        {
          id: 2,
          icon: "flaticon-website",
          title: "Web",
        },
        {
          id: 3,
          icon: "flaticon-apple",
          title: "ios",
        },
        {
          id: 4,
          icon: "flaticon-television",
          title: "TV",
        },
        {
          id: 5,
          icon: "flaticon-smartwatch",
          title: "Watch",
        },
        {
          id: 6,
          icon: "flaticon-cyber-security",
          title: "IoT",
        },
      ],
    };
  },
};
</script>