<template>
  <div class="case-study-area pt-100 pb-70">
    <div class="container-fluid p-0">
      <div class="section-title text-center">
        <span class="sp-color2">Case Study</span>
        <h2>Introduce Our Projects and Check Recent Work</h2>
      </div>

      <swiper
        :spaceBetween="25"
        :autoplay="{
          delay: 4000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          0: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          600: {
            spaceBetween: 15,
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        }"
        :modules="modules"
        class="case-study-slider pt-45"
      >
        <swiper-slide class="text-center" v-for="item in items" :key="item.id">
          <div class="case-study-item">
            <router-link to="/case-study-details">
              <img :src="item.image" alt="Images" />
            </router-link>
            <div class="content">
              <h3>
                <router-link to="/case-study-details">{{
                  item.title
                }}</router-link>
              </h3>
              <ul>
                <li v-for="tag in item.tags" :key="tag.id">
                  <router-link to="/case-study">{{ tag.tag }}</router-link>
                </li>
              </ul>
              <router-link to="case-details" class="more-btn">
                <i class="bx bx-right-arrow-alt"></i>
              </router-link>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";

export default {
  name: "CaseStudy",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          image: require("../../assets/images/case-study/case-study1.jpg"),
          title: "Business Solution",
          tags: [
            {
              id: 1,
              tag: "Business",
            },
            {
              id: 2,
              tag: "Planing",
            },
          ],
        },
        {
          id: 2,
          image: require("../../assets/images/case-study/case-study2.jpg"),
          title: "Digital Marketing",
          tags: [
            {
              id: 1,
              tag: "Digital",
            },
            {
              id: 2,
              tag: "Marketing",
            },
          ],
        },
        {
          id: 3,
          image: require("../../assets/images/case-study/case-study3.jpg"),
          title: "Strategic Planing",
          tags: [
            {
              id: 1,
              tag: "Strategic",
            },
            {
              id: 2,
              tag: "Planing",
            },
          ],
        },
        {
          id: 4,
          image: require("../../assets/images/case-study/case-study4.jpg"),
          title: "Design & Development",
          tags: [
            {
              id: 1,
              tag: "Design",
            },
            {
              id: 2,
              tag: "Development",
            },
          ],
        },
        {
          id: 5,
          image: require("../../assets/images/case-study/case-study5.jpg"),
          title: "Competitor Analysis",
          tags: [
            {
              id: 1,
              tag: "Business",
            },
            {
              id: 2,
              tag: "Analysis",
            },
          ],
        },
        {
          id: 6,
          image: require("../../assets/images/case-study/case-study6.jpg"),
          title: "Social Media Marketing",
          tags: [
            {
              id: 1,
              tag: "Media",
            },
            {
              id: 2,
              tag: "Marketing",
            },
          ],
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>