<template>
  <div class="banner-area">
    <div class="container-fluid">
      <div class="container-max">
        <div class="banner-item-content banner-item-ptb">
          <h1>We Provide Best EdTech and IT Services for Your Need</h1>
          <p>
            Aenean Sollicitudin, Lorem quis Bibendum auctor, nisi elit consequat
            ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh
            vulputate cursus a sit amet
          </p>
          <div class="banner-btn">
            <router-link
              to="/about"
              class="default-btn btn-bg-two border-radius-50"
            >
              Learn More
              <i class="bx bx-chevron-right"></i>
            </router-link>
            <router-link
              to="/contact"
              class="default-btn btn-bg-one border-radius-50 ml-20"
            >
              Get A Quote
              <i class="bx bx-chevron-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>