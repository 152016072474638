<template>
  <div class="technology-area-four pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="technology-right">
            <div class="section-title">
              <span class="sp-color2">Technology Index</span>
              <h2>We Deliver Our Best Solution With The Goal of Trusting</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vitae ante eget odio egestas pretium. Vestibulum sagittis dictum
                sem, imperdiet consectetur nunc vulputate non. Duis sit amet
                aliquet velit. Ut malesuada urna et ultrices efficitur. Nulla
                turpis dui, rhoncus sed erat quis, pharetra scelerisque leo.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-3 col-6 col-sm-3">
                <div class="technology-item">
                  <i class="flaticon-android"></i>
                  <h3>Android</h3>
                </div>
              </div>

              <div class="col-lg-3 col-6 col-sm-3">
                <div class="technology-item">
                  <i class="flaticon-website"></i>
                  <h3>Web</h3>
                </div>
              </div>

              <div class="col-lg-3 col-6 col-sm-3">
                <div class="technology-item">
                  <i class="flaticon-apple"></i>
                  <h3>ios</h3>
                </div>
              </div>

              <div class="col-lg-3 col-6 col-sm-3">
                <div class="technology-item">
                  <i class="flaticon-television"></i>
                  <h3>TV</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="technology-play-area">
            <a
              class="play-btn popup-btn"
              style="cursor: pointer"
              v-on:click="isPopupMethod(isPopup)"
            >
              <i class="bx bx-play"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnologyIndex",
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>