<template>
  <div class="case-study-area about-bg2 pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Case Study</span>
        <h2>Reads Now Our Recent Projects Work</h2>
      </div>

      <div class="row justify-content-center pt-45">
        <div class="col-lg-4 col-sm-6" v-for="item in items" :key="item.id">
          <div class="case-study-item2">
            <i :class="['icon-services', item.icon]"></i>
            <div class="content">
              <h3>
                <router-link to="/case-study-details">{{
                  item.title
                }}</router-link>
              </h3>
              <p>{{ item.shortDesc }}</p>
              <router-link to="/case-study-details" class="more-btn">
                <i class="bx bx-right-arrow-alt"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStudy",
  data() {
    return {
      items: [
        {
          id: 1,
          icon: "flaticon-consultant",
          title: "Enterprise Training Development",
          shortDesc:
            "We offer highly customized learning solutions to suit your training needs and preferences. Our dedicated teams assess and understand your organizational culture, evaluate the content and align themselves with your training objectives, in order to achieve the highest quality of training materials. By combining interesting interface, text, interactive graphics and media, with training strategies, we create consistent and comprehensive mobile-first programs which can help change the way your employees learn. With smart designs and interactive learning resources, these eLearning solutions will enrich your enterprise training programs.",
        },
        {
          id: 2,
          icon: "flaticon-web-development",
          title: "Animation & Video Development",
          shortDesc:
            "Nothing explains better than an audio-visual rendition. And, with growth of digital marketing, video content has grown to be the most powerful tool to reach out to your current and potential audience. Our team of video producers and editors use their unique storytelling skills to create animated logos, motion graphics, on-screen characters, animated short films, and infographics. We help you create videos for marketers, educators, corporates, and startups, which reflect your organization’s persona and gets across your message, instructions, or information in a comprehensive, yet interesting manner.",
        },
        {
          id: 3,
          icon: "flaticon-stats",
          title: "Educational / K12 Development",
          shortDesc:
            "To suit the needs of the changing academic content requirement, we develop Smart Class content and training materials for K-12 students. Our expert coaches and mentors, trained in various faculties design the most intuitive programs to help strengthen the linguistic, logical, mathematical, interpersonal, and naturalistic skills of modern students. We also provide eBook solutions from editing and proofreading to publishing guidance. By harnessing the power of educational tech, we bring a difference to the education of every child and prepare them for a better future.",
        },
      ],
    };
  },
};
</script>