<template>
  <div class="inner-banner">
    <div class="container">
      <div class="inner-title text-center">
        <h3>{{ pageTitle }}</h3>
        <ul>
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li>
            <i class="bx bx-chevrons-right"></i>
          </li>
          <li>{{ mainTitle }}</li>
        </ul>
      </div>
    </div>
    <div class="inner-shape">
      <img src="../../assets/images/shape/inner-shape.png" alt="Images" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["pageTitle", "mainTitle"],
};
</script>