<template>
  <div class="side-bar-area">
    <div class="side-bar-widget">
      <h3 class="title">Our Services</h3>
      <div class="side-bar-categories">
        <ul>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              IT Services
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Cloud Computing
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Web Development
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Business Reform
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Infrastructure
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Data Analysis
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Tag Cloud</h3>
      <ul class="side-bar-widget-tag">
        <li>
          <router-link to="/services" target="_blank"> Android </router-link>
        </li>
        <li>
          <router-link to="/services" target="_blank"> Creative </router-link>
        </li>
        <li>
          <router-link to="/services" target="_blank"> App </router-link>
        </li>
        <li>
          <router-link to="/services" target="_blank"> IOS </router-link>
        </li>
        <li>
          <router-link to="/services" target="_blank"> Business </router-link>
        </li>
        <li>
          <router-link to="/services" target="_blank"> Consulting </router-link>
        </li>
      </ul>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Gallery</h3>
      <ul class="blog-gallery">
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img1.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img2.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img3.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img4.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img5.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img6.jpg"
              alt="image"
            />
          </a>
        </li>
      </ul>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Archive</h3>
      <div class="side-bar-categories">
        <ul>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Design
              <span>[70]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Business
              <span>[24]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Development
              <span>[08]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Technology
              <span>[17]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Startup
              <span>[20]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/service-details" target="_blank">
              Marketing Growth
              <span>[13]</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
};
</script>