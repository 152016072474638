<template>
  <div class="banner-four-area">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="banner-four-content">
            <span>EdTech & IT TECHNOLOGY SOLUTIONS</span>
            <h1>Exceptional EdTech Services for Empowering Education through Technology</h1>
            <p>
              Lucreskill offers the industry-leading suite of products and services that empowers you to create and deliver more immersive, engaging, and effective learning and IT Solutions.
            </p>
            <div class="banner-btn">
              <router-link
                to="/contact"
                class="default-btn btn-bg-two border-radius-50"
              >
                Get A Quote
                <i class="bx bx-chevron-right"></i>
              </router-link>
              <a
                class="play-btn ml-20"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
                <h3>Watch Video</h3>
                <span>2 Minutes</span>
              </a>
            </div>
          </div>
        </div>

        <div class="col-lg-6 pe-0">
          <div class="banner-four-img">
            <img
              src="../../assets/images/home-four/home-four-img.png"
              alt="Images"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>