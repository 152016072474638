<template>
  <div class="team-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Team</span>
        <h2>Our Team Members</h2>
      </div>
      <div class="row pt-45 justify-content-center">
        <div class="col-lg-4 col-md-6" v-for="team in teams" :key="team.id">
          <div class="team-item">
            <img :src="team.image" alt="Team Images" />
            <div class="content">
              <h3>{{ team.name }}</h3>
              <span>{{ team.designation }}</span>
              <ul class="social-link">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
  data() {
    return {
      teams: [
        {
          id: 1,
          image: require("../../assets/images/team/team-img1.jpg"),
          name: "Adam Smith",
          designation: "President & CEO",
        },
        {
          id: 2,
          image: require("../../assets/images/team/team-img2.jpg"),
          name: "Max Angles",
          designation: "Manager",
        },
        {
          id: 3,
          image: require("../../assets/images/team/team-img3.jpg"),
          name: "Park Anderson",
          designation: "Sales Executive",
        },
      ],
    };
  },
};
</script>