<template>
  <div class="blog-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Latest Blog</span>
        <h2>Let’s Check Some Latest Blog</h2>
      </div>

      <div class="row pt-45 justify-content-center">
        <div class="col-lg-4 col-md-6" v-for="blog in blogs" :key="blog.id">
          <div class="blog-card">
            <div class="blog-img">
              <router-link to="/blog-details">
                <img :src="blog.image" alt="Blog Images" />
              </router-link>
              <div class="blog-tag">
                <h3>{{ blog.date }}</h3>
              </div>
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link to="/blog-one">
                    <i class="bx bxs-user"></i>
                    By {{ blog.user }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/blog-one">
                    <i class="bx bx-purchase-tag-alt"></i>
                    {{ blog.tag }}
                  </router-link>
                </li>
              </ul>

              <h3>
                <router-link to="/blog-details">
                  {{ blog.title }}
                </router-link>
              </h3>
              <p>{{ blog.desc }}</p>
              <router-link to="/blog-details" class="read-btn">
                Read More
                <i class="bx bx-chevron-right"></i>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 text-center">
          <div class="pagination-area">
            <a href="/blog-two" class="prev page-numbers">
              <i class="bx bx-left-arrow-alt"></i>
            </a>

            <span class="page-numbers current" aria-current="page">1</span>
            <a href="/blog-two" class="page-numbers">2</a>
            <a href="/blog-two" class="page-numbers">3</a>

            <a href="/blog-two" class="next page-numbers">
              <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogContent",
  data() {
    return {
      blogs: [
        {
          id: 1,
          image: require("../../assets/images/blog/blog-img1.jpg"),
          date: "11 March",
          user: "Admin",
          tag: "Business",
          title: "Product Idea Solution for New Generation",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
        {
          id: 2,
          image: require("../../assets/images/blog/blog-img2.jpg"),
          date: "12 March",
          user: "Admin",
          tag: "Invention",
          title: "New Device Invention for Digital Platform",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
        {
          id: 3,
          image: require("../../assets/images/blog/blog-img3.jpg"),
          date: "13 March",
          user: "Admin",
          tag: "Achive",
          title: "Business Strategy Make His Goal Acheive",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
        {
          id: 4,
          image: require("../../assets/images/blog/blog-img4.jpg"),
          date: "11 March",
          user: "Admin",
          tag: "Planing",
          title: "10 Ways To Get Efficient Result and Benefits",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
        {
          id: 5,
          image: require("../../assets/images/blog/blog-img5.jpg"),
          date: "12 March",
          user: "Admin",
          tag: "App",
          title: "Top 10 Ways How to Develop Mobile Application",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
        {
          id: 6,
          image: require("../../assets/images/blog/blog-img6.jpg"),
          date: "13 March",
          user: "Admin",
          tag: "Achive",
          title: "How to Make Secure for Your Personal Information",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum.",
        },
      ],
    };
  },
};
</script>