<template>
  <div class="work-process-area-two pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-5">
          <div class="work-process-left">
            <div class="section-title">
              <span class="sp-color1">Our Working Process</span>
              <h2>How Our Services Will Help You to Grow Your Business</h2>
            </div>
            <router-link
              to="/contact"
              class="default-btn btn-bg-two border-radius-50 text-center"
            >
              Get A Quote
            </router-link>
          </div>
        </div>

        <div class="col-lg-7">
          <div class="row">
            <div
              class="col-lg-6 col-sm-6"
              v-for="proces in process"
              :key="proces.id"
            >
              <div class="work-process-card">
                <i :class="proces.icon"></i>
                <h3>{{ proces.title }}</h3>
                <p>{{ proces.shortDesc }}</p>
                <div class="number">{{ proces.number }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurWorkingProcess",
  data() {
    return {
      process: [
        {
          id: 1,
          icon: "flaticon-project",
          title: "Discovery",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "01",
        },
        {
          id: 2,
          icon: "flaticon-chip",
          title: "Planning",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "02",
        },
        {
          id: 3,
          icon: "flaticon-effective",
          title: "Execute",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "03",
        },
        {
          id: 4,
          icon: "flaticon-bullhorn",
          title: "Deliver",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "04",
        },
      ],
    };
  },
};
</script>