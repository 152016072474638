<template>
  <div class="contact-form-area pt-100 pb-70">
    <div class="container">
      <div class="row pt-45">
        <div class="col-lg-4"></div>
        <div class="col-lg-8">
          <div class="section-title text-right">
            <h3>Please share your queries completing the form below. We will reply to you shortly.</h3>
          </div>
        </div>
      </div>
      <form ref="form" @submit.prevent="sendEmail">
        <div class="row pt-45">
          <div class="col-lg-4">
            <div class="contact-info mr-20">
              <span>Contact Info</span>
              <h2>Let's Connect With Us</h2>
              <ul>
                <li>
                  <div class="content">
                    <i class="bx bx-phone-call"></i>
                    <h3>Phone Number</h3>
                    <a href="tel:+1(214)-431-5175">+1 (214) 431-5175</a>
                  </div>
                </li>
                <li>
                  <div class="content">
                    <i class="bx bxs-map"></i>
                    <h3>USA - Texas</h3>
                    <span>539 W. Commerce St #8077 Dallas, TX-75208</span>
                  </div>
                </li>
                <li>
                  <div class="content">
                    <i class="bx bx-phone-call"></i>
                    <h3>Phone Number</h3>
                    <a href="tel:+91 7709138516">+91 77091 38516</a>
                  </div>
                </li>
                <li>
                  <div class="content">
                    <i class="bx bxs-map"></i>
                    <h3>India - Pune</h3>
                    <span>RH-4, Kanchanpushpa, Shelarmala, Katraj, Pune 411046</span>
                  </div>
                </li>
                <li>
                  <div class="content">
                    <i class="bx bx-message"></i>
                    <h3>Contact Info</h3>
                    <a href="mailto:info@lucreskilltech.com">info@lucreskilltech.com</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        
          <div class="col-lg-8">
            <div class="contact-form">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Your Name <span>*</span></label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        class="form-control"
                        required
                        placeholder="Name"
                      />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Your Email <span>*</span></label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        class="form-control"
                        required
                        placeholder="Email"
                      />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Phone Number <span>*</span></label>
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        required
                        class="form-control"
                        placeholder="Phone Number"
                      />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Your Subject <span>*</span></label>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        class="form-control"
                        required
                        placeholder="Your Subject"
                      />
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Your Message <span>*</span></label>
                      <textarea
                        name="message"
                        class="form-control"
                        id="message"
                        cols="30"
                        rows="8"
                        required
                        placeholder="Your Message"
                      ></textarea>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="agree-label">
                      <input type="checkbox" id="chb1" />
                      <label for="chb1">
                        Accept
                        <router-link to="/terms-condition"
                          >Terms & Conditions</router-link
                        >
                        And
                        <router-link to="/privacy-policy"
                          >Privacy Policy.</router-link
                        >
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 text-center">
                    <button
                      type="submit"
                      class="default-btn btn-bg-two border-radius-50"
                    >
                      Send Message <i class="bx bx-chevron-right"></i>
                    </button>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';
export default {
  name: 'ContactForm',
  data(){
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      endpoint: 'https://lucreskilltech.com/contact',
    };
  },

  methods: {
    // sendEmail1() {
    //   console.log(this.$refs.form)
    //   try {
    //     emailjs.sendForm('service_8tf5hg3', 'template_36ns5k8', this.$refs.form,
    //      {
    //       publicKey: 'yVYQ5pI46quEpHPKF',
    //     })
    //     .then(
    //       () => {
    //         console.log('You have successfully submitted your message!');
    //       }
    //     );
    //   } catch(error) {
    //       console.log({error})
    //   }
    //   // Reset form field
    //   this.name = ''
    //   this.email = ''
    //   this.message = ''
    // },
    sendEmail() {
      emailjs
        .sendForm('service_8tf5hg3', 'template_36ns5k8', this.$refs.form, {
          publicKey: 'yVYQ5pI46quEpHPKF',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            alert('You have successfully submitted your message');
            // this.subject = ''
            // this.name = ''
            // this.email = ''
            // this.message = ''
            this.$refs.form.reset();
          },
          (error) => {
            console.log('FAILED...', error.text);
            alert('There is error encountered, Please try later');
          },
        );
         //Reset form field
         this.$refs.form.reset();

    },
  }
};
</script> 
<!--
<template>
  <form ref="form" @submit.prevent="sendEmail">
    <label>Name</label>
    <input type="text" name="user_name">
    <label>Email</label>
    <input type="email" name="user_email">
    <label>Message</label>
    <textarea name="message"></textarea>
    <input type="submit" value="Send">
  </form>
</template>

<script>
import emailjs from '@emailjs/browser';
//import emailjs from 'emailjs-com';
export default {
  methods: {
    sendEmail() {
      emailjs
        .sendForm('service_8tf5hg3', 'template_36ns5k8', this.$refs.form, {
          publicKey: 'yVYQ5pI46quEpHPKF',
        })
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    },
  },
};
</script>-->