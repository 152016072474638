<template>
  <div class="security-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">IT Security & Computing</span>
        <h2>
          Searching for a Solution! We Provide Truly Prominent IT Solutions
        </h2>
      </div>
      <div class="row pt-45 justify-content-center">
        <div class="col-lg-4 col-sm-6" v-for="card in cards" :key="card.id">
          <div class="security-card">
            <i :class="card.icon"></i>
            <h3>
              <router-link to="/case-study-details">{{
                card.title
              }}</router-link>
            </h3>
            <p>{{ card.shortDesc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ITSecurity",
  data() {
    return {
      cards: [
        {
          id: 1,
          icon: "flaticon-cyber-security",
          title: "Enterprise Training Development",
          shortDesc:
            "We offer highly customized learning solutions to suit your training needs and preferences. Our dedicated teams assess and understand your organizational culture, evaluate the content and align themselves with your training objectives, in order to achieve the highest quality of training materials. By combining interesting interface, text, interactive graphics and media, with training strategies, we create consistent and comprehensive mobile-first programs which can help change the way your employees learn. With smart designs and interactive learning resources, these eLearning solutions will enrich your enterprise training programs.",
        },
        {
          id: 2,
          icon: "flaticon-computer",
          title: "Animation & Video Development",
          shortDesc:
            "Nothing explains better than an audio-visual rendition. And, with growth of digital marketing, video content has grown to be the most powerful tool to reach out to your current and potential audience. Our team of video producers and editors use their unique storytelling skills to create animated logos, motion graphics, on-screen characters, animated short films, and infographics. We help you create videos for marketers, educators, corporates, and startups, which reflect your organization’s persona and gets across your message, instructions, or information in a comprehensive, yet interesting manner.",
        },       
        {
          id: 3,
          icon: "flaticon-implement",
          title: "Educational / K12 Development",
          shortDesc:
            "To suit the needs of the changing academic content requirement, we develop Smart Class content and training materials for K-12 students. Our expert coaches and mentors, trained in various faculties design the most intuitive programs to help strengthen the linguistic, logical, mathematical, interpersonal, and naturalistic skills of modern students. We also provide eBook solutions from editing and proofreading to publishing guidance. By harnessing the power of educational tech, we bring a difference to the education of every child and prepare them for a better future.",
        },
        {
          id: 4,
          icon: "flaticon-effective",
          title: "Localization and Translation Solutions",
          shortDesc:
            "Our language and technology experts have the right cultural knowhow to guide you through content development for respective regions or countries. Our competent translators are experienced and certified in their native language skills and offer a vast range of translations for course materials, academic collaterals, so your course is not restricted by language and assumes global acceptance. We ensure strict review and quality checks of every translated material so you are assured of high-end deliverables.",
        },
        {
          id: 5,
          icon: "flaticon-consulting",
          title: "Higher Education Solutions",
          shortDesc:
            "E-Learning in Higher Education is currently contributing to a paradigm shift. Changing workforce demographics, acute skills shortage, and ongoing economic uncertainties are compelling employers to rethink traditional approaches to talent management.Both businesses and the Higher Education Institutes (HEIs) have been slow to adapt their educational models to cater to both – the changing needs of the workforce and the changing profiles of the learners, who are clearly moving away from macro to micro/nano degrees. There’s an evident need for better collaboration between businesses and the HEIs.",
        },
        {
          id: 6,
          icon: "flaticon-consultant",
          title: "Flash to HTML5 Conversion",
          shortDesc:
            "Converting your old Flash-based courses to HTML5 mobile responsive elearning courses When an organization want to convert their Flash based courses to HTML5, the major constrain will be the budget. Migration is an overhead cost to the organization, especially if the courses are in high number. Considering the budget and the timeline, here are different approaches we follow for converting from Flash to HTML5.",
        },
      ],
    };
  },
};
</script>