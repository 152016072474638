<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/sex-harassment.jpeg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>Sexual Harassment</h2>
              <p>                
                Its an behaviour characterized by the making of unwelcome and inappropriate sexual remarks or physical advances in a workplace or other professional or social situation.
                This Online e-learning sexual harassment training provides some distinct advantages including: the ability to standardize training, flexibility and control of the venues, times training and eliminating any bias that may occur related to the person conducting the training session. Such online harassment prevention training helps resolve many of these issues.
              </p>

              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Actual or attempted rape or sexual assault.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Whistling at someone.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Unwanted deliberate touching, leaning over, cornering or pinching.
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Kissing sounds, howling and smacking lips.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Touching an employee’s clothing, hair or body.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Unwanted sexual teasing, jokes, remarks or questions.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Touching or rubbing oneself sexually around another person.
                    </li>                   
                  </ul>
                </div>
              </div>
              <p>
                Sexual harassment has been identified as a term which is difficult to define as it involves a range of behaviors. Efforts have been made at both national and international levels to define this term effectively. Often, the term is subjected to different interpretations. Some believe that it is better not to mingle with female colleagues so that one does not get embroiled in a sexual harassment complaint. The reality of sexual harassment incidents at the workplace is that there is more to worry about under-reporting, than people misusing the law.
              </p>
              <h2>Product Demo</h2>
              <p>
                For Product Case Study, Live Demo please visit :             
                <a href="https://product.lucreskilltech.com/assests/Demo/SH/story_html5.html" target="_blank">Prevention of Sexual Harrashment.</a>
              </p>
              <h4>For Purchase</h4>  
              <p>                          
                <!--<a href="https://product.lucreskilltech.com/" target="_blank">Sexual Harrashment.</a>-->
                Please contact Support at : <ul>
                                                <li>
                                                  <b>+91 77091 38516</b>
                                                </li>
                                                <li>
                                                  <b>+1 (214) 431-5175</b>
                                                </li>
                                              </ul>  
              </p>
            </div>

            <div class="case-play-btn">
              <a
                class="case-play"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>

            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucreskill Tech provide wide range of benefits to various stakeholders, including learners, organizations, and instructors.
                We often costs less than traditional in-person education. There are no commuting expenses, and many elearning courses are more affordable than their in-person equivalents.
                Elearning platforms often provide tools for tracking learners' progress and performance. Instructors and organizations can use this data to evaluate and improve educational outcomes.
                Professionals can use elearning to stay up-to-date with the latest industry developments, certifications, and best practices.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <p>
                eLearning offers numerous benefits for education and trainings in all aspects. 
                These advantages can enhance the learning experience for students, 
                teachers, and schools along with access to a vast array of educational resources, 
                including online textbooks, interactive simulations, virtual labs, and multimedia 
                content, enriching the learning experience.
              </p>
            </div>

            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through 
                creativity, originality, and customization to meet the 
                needs of specific audience. By focusing on these aspects 
                at each stage of development, we are creating eLearning 
                materials that stand out and effectively engage learners.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "CaseStudyDetails",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>