<template>
  <div class="blog-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Latest Blog</span>
        <h2>Let’s Check Some Latest Blog</h2>
      </div>

      <div class="row pt-45 justify-content-center">
        <div class="col-lg-4 col-md-6" v-for="blog in blogs" :key="blog.id">
          <div class="blog-item">
            <div class="blog-img">
              <router-link to="/blog-details">
                <img :src="blog.image" alt="Blog Images" />
              </router-link>
              <router-link to="/blog" class="blog-tag">
                {{ blog.tag }}
              </router-link>
            </div>
            <div class="content">
              <ul>
                <li>{{ blog.date }}</li>
                <li>
                  <router-link to="/blog">
                    <i class="bx bxs-user"></i>
                    By {{ blog.user }}
                  </router-link>
                </li>
              </ul>
              <h3>
                <router-link to="/blog-details">{{ blog.title }}</router-link>
              </h3>
              <p>{{ blog.desc }}</p>
              <!--<router-link to="/blog-details" class="read-btn">
                Read More
                <i class="bx bx-chevron-right"></i>
              </router-link>
              -->
              Read More
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LatestBlog",
  data() {
    return {
      blogs: [
        {
          id: 1,
          image: require("../../assets/images/blog/blog-img7.jpg"),
          date: "5 July",
          user: "Admin",
          tag: "Business",
          title: "eLearning Innovation: Ground Rules for What Comes Next",
          desc: "Distance learning is always changing. But, what kind of learning innovations will people dream up next? What do people think about the future of eLearning?  Take a close look at this article and find out what some experts say.",
        },
        {
          id: 2,
          image: require("../../assets/images/blog/blog-img8.jpg"),
          date: "27 June",
          user: "Admin",
          tag: "Invention",
          title: "Boring to Brilliant: 5 Tips to Convert Your eLearning To a Story-based Approach",
          desc: "Storytelling is a powerful tool in education. Stories give adult learners a relevant context, making them personally-involved in the material. This article gives five valuable tips for turning boring PowerPoint into brilliant eLearning courses through storytelling.",
        },
        {
          id: 3,
          image: require("../../assets/images/blog/blog-img9.jpg"),
          date: "25 May",
          user: "Admin",
          tag: "Achieve",
          title: "E-learning and its effects on teaching and learning in a global age",
          desc: "E-learning is the use of Internet technologies to enhance knowledge and performance.E-learning technologies offer learners control over content, learning sequence, pace of learning, time, and often media, allowing them to tailor their experiences to meet their personal learning objectives.",
        },
      ],
    };
  },
};
</script>