<template>
  <div class="technology-area-three pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="section-title">
            <span class="sp-color1">Technology Index</span>
            <h2>We Deliver Our Best Solution With The Goal of Trusting</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vitae ante eget odio egestas pretium. Vestibulum sagittis dictum
              sem, imperdiet consectetur nunc vulputate non. Duis sit amet
              aliquet velit. Ut malesuada urna et ultrices efficitur. Nulla
              turpis dui, rhoncus sed erat quis, pharetra scelerisque leo.
            </p>
            <router-link
              to="/contact"
              class="default-btn btn-bg-two border-radius-50 text-center"
            >
              Get A Quote
            </router-link>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="row justify-content-center">
            <div
              class="col-lg-4 col-6 col-md-4"
              v-for="technology in technologys"
              :key="technology.id"
            >
              <div class="technology-card technology-card-color2">
                <i :class="technology.icon"></i>
                <h3>{{ technology.title }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechnologyIndex",
  data() {
    return {
      technologys: [
        {
          id: 1,
          icon: "flaticon-android",
          title: "Android",
        },
        {
          id: 2,
          icon: "flaticon-website",
          title: "Web",
        },
        {
          id: 3,
          icon: "flaticon-apple",
          title: "ios",
        },
        {
          id: 4,
          icon: "flaticon-television",
          title: "TV",
        },
        {
          id: 5,
          icon: "flaticon-smartwatch",
          title: "Watch",
        },
        {
          id: 6,
          icon: "flaticon-cyber-security",
          title: "IoT",
        },
      ],
    };
  },
};
</script>