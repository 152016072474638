import { createWebHistory, createRouter } from "vue-router";

//import MainNavBar from '../components/Layouts/MainNavbar.vue';

import HomePage from "../components/Pages/HomePage";
import HomeOnePage from "../components/Pages/HomeOnePage";
import HomeTwoPage from "../components/Pages/HomeTwoPage";
import HomeThreePage from "../components/Pages/HomeThreePage";
import HomeFourPage from "../components/Pages/HomeFourPage";
import HomeFivePage from "../components/Pages/HomeFivePage";
import HomeSixPage from "../components/Pages/HomeSixPage";
import AboutPage from "../components/Pages/AboutPage";
import TeamPage from "../components/Pages/TeamPage";
import TestimonialsPage from "../components/Pages/TestimonialsPage";
import FaqPage from "../components/Pages/FaqPage";
import CaseStudyPage from "../components/Pages/CaseStudyPage";
/*import CaseStudyDetailsPage from "../components/Pages/CaseStudyDetailsPage";*/
import LogInPage from "../components/Pages/LogInPage";
import RegisterPage from "../components/Pages/RegisterPage";
import ForgetPasswordPage from "../components/Pages/ForgetPasswordPage";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import ErrorPage from "../components/Pages/ErrorPage";
import ComingSoonPage from "../components/Pages/ComingSoonPage";
import ServicesPage from "../components/Pages/ServicesPage";
import ServicesTwoPage from "../components/Pages/ServicesTwoPage";
import ServiceDetailsPage from "../components/Pages/ServiceDetailsPage";
import BlogPage from "../components/Pages/BlogPage";
import BlogTwoPage from "../components/Pages/BlogTwoPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import ContactPage from "../components/Pages/ContactPage";
import FireSafetyPage from "../components/Pages/FireSafetyPage";
import SexHarassmentPage from "../components/Pages/SexHarassmentPage";
import AnimationPage from "../components/Pages/AnimationPage";
import K12EducationPage from "../components/Pages/K12EducationPage";
import LocaleTranslatePage from "../components/Pages/LocaleTranslatePage";
import FlashTHTMLPage from "../components/Pages/FlashTHTMLPage";
import StaffAugPage from "../components/Pages/StaffAugPage";
import CustomDevelopmentPage from "../components/Pages/CustomDevelopmentPage";
import WebDevelopmentPage from "../components/Pages/WebDevelopmentPage";
import AppServicesPage from "../components/Pages/AppServicesPage";
import CareersPage from "../components/Pages/CareersPage";

const routes = [
  { path: "/", name: "HomePage", component: HomePage },
  { path: "/home-one", name: "HomeOnePage", component: HomeOnePage },
  { path: "/home-two", name: "HomeTwoPage", component: HomeTwoPage },
  { path: "/home-three", name: "HomeThreePage", component: HomeThreePage },
  { path: "/home-four", name: "HomeFourPage", component: HomeFourPage },
  { path: "/home-five", name: "HomeFivePage", component: HomeFivePage },
  { path: "/home-six", name: "HomeSixPage", component: HomeSixPage },
  { path: "/about", name: "AboutPage", component: AboutPage },
  { path: "/team", name: "TeamPage", component: TeamPage },
  {
    path: "/testimonials",
    name: "TestimonialsPage",
    component: TestimonialsPage,
  },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  { path: "/case-study", name: "CaseStudyPage", component: CaseStudyPage },
  /*{
    path: "/case-study-details",
    name: "CaseStudyDetailsPage",
    component: CaseStudyDetailsPage,
  },*/
  { path: "/log-in", name: "LogInPage", component: LogInPage },
  { path: "/register", name: "RegisterPage", component: RegisterPage },
  {
    path: "/forget-password",
    name: "ForgetPasswordPage",
    component: ForgetPasswordPage,
  },
  {
    path: "/terms-condition",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/coming-soon", name: "ComingSoonPage", component: ComingSoonPage },
  { path: "/services", name: "ServicesPage", component: ServicesPage },
  {
    path: "/services-two",
    name: "ServicesTwoPage",
    component: ServicesTwoPage,
  },
  {
    path: "/service-details",
    name: "ServiceDetailsPage",
    component: ServiceDetailsPage,
  },
  { path: "/blog", name: "BlogPage", component: BlogPage },
  { path: "/blog-two", name: "BlogTwoPage", component: BlogTwoPage },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  { path: "/contact", name: "ContactPage", component: ContactPage },
  { path: "/FireSafety", name: "FireSafetyPage", component: FireSafetyPage },
  { path: "/SexHarassment", name: "SexHarassmentPage", component: SexHarassmentPage },
  { path: "/Animation", name: "AnimationPage", component: AnimationPage },
  { path: "/K12Education", name: "K12EducationPage", component: K12EducationPage },
  { path: "/LocaleTranslate", name: "LocaleTranslatePage", component: LocaleTranslatePage },
  { path: "/FlashTHTML", name: "FlashTHTMLPage", component: FlashTHTMLPage },
  { path: "/StaffAug", name: "StaffAugPage", component: StaffAugPage },
  { path: "/StaffAug", name: "StaffAugPage", component: StaffAugPage },
  { path: "/CustomDevelopment", name: "CustomDevelopmentPage", component: CustomDevelopmentPage },
  { path: "/WebDevelopment", name: "WebDevelopmentPage", component: WebDevelopmentPage },
  { path: "/AppServices", name: "AppServicesPage", component: AppServicesPage },
  { path: "/Career", name: "CareersPage", component: CareersPage },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  mode: "history",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
