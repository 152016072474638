<template>
  <div class="side-bar-area">
    <div class="side-bar-widget">
      <h3 class="title">Recent Project</h3>
      <div class="side-bar-categories">
        <ul>
          <li>
            <div class="line-circle"></div>
            <router-link to="/case-study-details" target="_blank"
              >Web Development</router-link
            >
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/case-study-details" target="_blank"
              >Social Media App</router-link
            >
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/case-study-details" target="_blank"
              >UI/UX Research</router-link
            >
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/case-study-details" target="_blank"
              >Business Reform</router-link
            >
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/case-study-details" target="_blank"
              >Consulting
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/case-study-details" target="_blank"
              >Data Analysis</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Tag Cloud</h3>
      <ul class="side-bar-widget-tag">
        <li>
          <router-link to="/case-study" target="_blank">ELearning</router-link>
        </li>
        <li>
          <router-link to="/case-study" target="_blank">Android</router-link>
        </li>
        <li>
          <router-link to="/case-study" target="_blank">Applications</router-link>
        </li>
        <li>
          <router-link to="/case-study" target="_blank">Creative</router-link>
        </li>
        <li>
          <router-link to="/case-study" target="_blank">IOS</router-link>
        </li>
        <li>
          <router-link to="/case-study" target="_blank">Designing</router-link>
        </li>
        <li>
          <router-link to="/case-study" target="_blank">Web</router-link>
        </li>
      </ul>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Gallery</h3>
      <ul class="blog-gallery">
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img1.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img2.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img3.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img4.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img5.jpg"
              alt="image"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/" target="_blank">
            <img
              src="../../assets/images/blog/blog-small-img6.jpg"
              alt="image"
            />
          </a>
        </li>
      </ul>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Archive</h3>
      <div class="side-bar-categories">
        <ul>
          <li>
            <div class="line-circle"></div>
           <!--<router-link to="/case-study-details" target="_blank">
              Design
              <span>[70]</span>
            </router-link> Removed path for hyperlinks--> 
            <router-link to="" target="_blank">
              Course Development
              <span>[100]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="" target="_blank">
              Content Development
              <span>[500]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="" target="_blank">
              Educational Animations
              <span>[4000]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="" target="_blank">
              Illustrations
              <span>[4000]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="" target="_blank">
              Software Applications
              <span>[20]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="" target="_blank">
              Web Development
              <span>[30]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="" target="_blank">
              Mobile Apps
              <span>[5]</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
};
</script>