<template>
  <div class="call-us-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="call-contact">
            <h3>Call Us 24/7</h3>
            <a href="tel:+1(212)255-5511" class="call-btn">+1 (212) 255-5511</a>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              mollis tempor nunc ac sollicitudin Interdum et malesuada fames ac
              ante ipsum primis.
            </p>
            <router-link
              to="/contact"
              class="default-btn btn-bg-two border-radius-5"
            >
              Contact Us
            </router-link>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="call-us-img">
            <img
              :src="require(`../../assets/images/${callUsImage}`)"
              alt="image"
            />
            <div class="call-shape">
              <div class="shape1">
                <img
                  src="../../assets/images/call-us/call-shap2.png"
                  alt="Images"
                />
              </div>

              <div class="shape2 shape2-rs">
                <img
                  src="../../assets/images/call-us/call-shap3.png"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallUs",
  props: ["callUsImage"],
};
</script>