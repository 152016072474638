<template>
  <footer class="footer-area footer-bg">
    <div class="container">
      <div class="footer-top pt-100 pb-70">
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <div class="footer-widget">
              <div class="footer-logo">
                <router-link to="/">
                  <img
                    src="../../assets/images/logos/4444.png"
                    alt="Images"
                  />
                </router-link>
              </div>
              <p>
                Empowering Individuals, Businesses, and Communities: Unleashing the Potential of EdTech eLearning.
              </p>
              <div class="footer-call-content">
                <h3>Talk to Our Support</h3>
                <span>
                  <a href="tel:+1(214)-431-5175">+1 (214) 431-5175</a>
                </span>
                <i class="bx bx-headphone"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget pl-2">
              <h3>Services</h3>
              <ul class="footer-list">
                <li>
                  <router-link to="/CustomDevelopment" >
                    <i class="bx bx-chevron-right"></i>
                    Custom Learning Solutions
                  </router-link>
                </li>
                <li>
                  <router-link to="/Animation" >
                    <i class="bx bx-chevron-right"></i>
                    Animation and Video Services
                  </router-link>
                </li>
                <li>
                  <router-link to="/K12Education" >
                    <i class="bx bx-chevron-right"></i>
                    Educational K12 Solutions
                  </router-link>
                </li>
                <li>
                  <router-link to="/LocaleTranslate" >
                    <i class="bx bx-chevron-right"></i>
                    Localization and Translation Solutions
                  </router-link>
                </li>
                <li>
                  <router-link to="/FlashTHTML" >
                    <i class="bx bx-chevron-right"></i>
                    Flash to HTML Conversion
                  </router-link>
                </li>
                <li>
                  <router-link to="/AppServices" >
                    <i class="bx bx-chevron-right"></i>
                    Application Services
                  </router-link>
                </li>
                <li>
                  <router-link to="/WebDevelopment" >
                    <i class="bx bx-chevron-right"></i>
                    Web Development
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <!--<div class="col-lg-3 col-sm-6">
            <div class="footer-widget pl-5">
              <h3>Our Blog</h3>
              <ul class="footer-blog">
                <li>
                  <router-link to="/blog-details">
                    <img
                      src="../../assets/images/blog/blog-img-footer.jpg"
                      alt="Images"
                    />
                  </router-link>
                  <div class="content">
                    <h3>
                      <router-link to="/blog-details">
                        Product Idea Solution For New Generation
                      </router-link>
                    </h3>
                    <span>04 Dec 2023</span>
                  </div>
                </li>

                <li>
                  <router-link to="/blog-details">
                    <img
                      src="../../assets/images/blog/blog-img-footer2.jpg"
                      alt="Images"
                    />
                  </router-link>
                  <div class="content">
                    <h3>
                      <router-link to="/blog-details">
                        New Device Invention for Digital Platform
                      </router-link>
                    </h3>
                    <span>07 Dec 2023</span>
                  </div>
                </li>

                <li>
                  <router-link to="/blog-details">
                    <img
                      src="../../assets/images/blog/blog-img-footer3.jpg"
                      alt="Images"
                    />
                  </router-link>
                  <div class="content">
                    <h3>
                      <router-link to="/blog-details">
                        Business Strategy Make His Goal Acheive
                      </router-link>
                    </h3>
                    <span>10 Dec 2023</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>-->

          <!--<div class="col-lg-3 col-sm-6">
            <div class="footer-widget pl-2">
              <h3>About Us</h3>
              <ul class="footer-list">
                <li>
                  <router-link to="/CustomDevelopment" >
                    <i class="bx bx-chevron-right"></i>
                    Sitemap
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          -->

          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3>Contact</h3>     
              <p><b>USA - Texas</b></p>
              <p>539 W. Commerce St. 
              <br />#8077 Dallas, TX-75208 </p>    
              <p>+91 77091 38516</p>  
              <div class="newsletter-area">
                <form class="newsletter-form" method="POST">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter Your Email"
                    name="EMAIL"
                    required
                  />
                  <button class="subscribe-btn" type="submit">
                    <i class="bx bx-paper-plane"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="copy-right-area">
        <div class="copy-right-text">
          <p>
            Copyright ©
            {{ currentYear }}
            Lucreskill. All Rights Reserved by
            <a href="https://lucreskilltech.com/" target="_blank">Lucreskill Tech Pvt. Ltd.</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>