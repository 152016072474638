<template>
  <div class="about-area ptb-100">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="about-content mr-20">
            <div class="section-title">
              <span class="sp-color2">12 Years of Experience</span>
              <h2>Right Partner for Software Innovation</h2>
              <p>
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio
                sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan
                ipsum velit nam nec tellus a odio tincidunt auctor.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-6">
                <div class="about-content-card">
                  <i class="flaticon-practice"></i>
                  <h3>Experience</h3>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-6">
                <div class="about-content-card">
                  <i class="flaticon-help"></i>
                  <h3>Quick Support</h3>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="about-img">
            <img
              src="../../assets/images/about/about-img1.jpg"
              alt="About Images"
            />
            <div class="sub-content">
              <img
                src="../../assets/images/about/about-img2.jpg"
                alt="About Images"
              />
              <div class="content">
                <h3>45</h3>
                <span>Satisfied Clients</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SoftwareInnovation",
};
</script>