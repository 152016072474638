<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/books-laptop.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>Custom Learning </h2>
              <p>                
                We offer highly customized learning solutions to suit your training needs and preferences. Our dedicated teams assess and understand your organizational culture, evaluate the content and align themselves with your training objectives, in order to achieve the highest quality of training materials. By combining interesting interface, text, interactive graphics and media, with training strategies, we create consistent and comprehensive mobile-first programs which can help change the way your employees learn. With smart designs and interactive learning resources, these eLearning solutions will enrich your enterprise training programs.
              </p>
            </div>
            <div class="case-article-content">
              <h2>Mobile Learning</h2>
              <p>                
                Mobile and smart devices have evolved to be the most sought-after platforms for learning. We help you be in sync with the well-informed workforce by developing cost-effective and scalable mobile learning solutions that meet the demands of the millennial learners. Our mobile solutions include:</p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <ul class="case-article-list case-article-rs case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>Mobile delivery of online, multi-media based course materials
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Converting Flash-based legacy courses to smart devices
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Training updates, eBooks, and job updates for mobile and smart devices
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-6 col-md-6">
                    <ul class="case-article-list case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>Gamification of training content to make it mobile-friendly
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Customized app-based mobile learning materials
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Creating animated avatars or on-screen mentors, full-motion videos, simulation
                      </li>                    
                    </ul>
                  </div>
              </div>
            </div>
            <div class="case-article-content">
              <h2>Rapid Authoring</h2>
              <p>                
                To enable a holistic eLearning environment for your employees, we integrate relevant industry-standard rapid authoring tools that meet your training and learning needs. Our authoring experts have a strong understanding and experience in developing smart authoring solutions like Articulate Storyline, Lectora and Captivate. We use these popular tools to create flash-based course materials and publish them across different devices. Each of these powerful, dynamic and engaging tools provide meaningful learning experiences to your employees.</p>
            </div>
            <div class="case-article-content">
              <h2>Instructor- led Training (ILTs)</h2>
              <p>                
                We understand the importance of instructor-led training and design solutions that help establish instant learner engagement. The learning activities of our ILT programs are designed with high visual appeal and interactive activities and integrated in each of your training module for better and faster learning experience of your workforce.</p>
            </div>
            <div class="case-article-content">
              <h2>Application Training</h2>
              <p>                
                Training on your teams on tech applications lies at the core of corporate training. With the latest techniques of application training, we help you prepare your team to be the next generation of professionals. Our high-impact materials using hand task-based simulations, mini-courses, demos and hands-on practices helps your employees with in-depth understanding and create an interesting learning setup.</p>
            </div>
            <div class="case-article-content">
              <h2>Virtual Reality Learning</h2>
              <p>                
                Unlike traditional user interfaces, VR places the user inside an experience. Instead of viewing a screen in front of them, users are immersed and able to interact with 3D worlds. By simulating as many senses as possible, such as vision, hearing, touch, even smell, the computer is transformed into a gatekeeper to this artificial world. The only limits to near-real VR experiences are the availability of content and cheap computing power.</p>
            </div>
            <div class="case-play-btn">
              <a
                class="case-play"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>

            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucreskill is often at the forefront of adopting new learning technologies and methodologies, 
                ensuring that your organization benefits from the latest advancements by assuring commitment 
                to keep content current and providing ongoing technical and customer support. We are adaptive 
                in learning techniques to tailor content to individual learner needs, providing a more 
                personalized experience with our cost effective solution compared to traditional in-person 
                training or education. Elearning can lead to increased job satisfaction and learner 
                satisfaction, as it empowers individuals to learn at their own pace and in their preferred 
                style.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>             
            </div>
            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through creativity, originality, and customization to meet the needs of specific audience. By focusing on these aspects at each stage of development, we are creating eLearning materials that stand out and effectively engage learners.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "AnimationVideoDev",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>