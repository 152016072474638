<template>
  <div class="services-area-four pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="services-left">
            <div class="section-title">
              <span class="sp-color2">Our Services</span>
              <h2>Searching for a Solution! We Provide Truly IT Solutions</h2>
              <p class="margin-auto">
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio
                sit amet nibh vulputate cursus a sit amet mauris Morbi accumsan
                ipsum velit.
              </p>
            </div>
            <router-link
              to="/services"
              class="default-btn btn-bg-two border-radius-50 text-center"
            >
              View All Services
            </router-link>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="row justify-content-center">
            <div
              class="col-lg-6 col-sm-6"
              v-for="service in services"
              :key="service.id"
            >
              <div class="services-card services-card-color-bg">
                <i :class="service.icon"></i>
                <h3>
                  <router-link to="/service-details">
                    {{ service.title }}
                  </router-link>
                </h3>
                <p>{{ service.shortDesc }}</p>
                <router-link to="/service-details" class="learn-btn">
                  Learn More
                  <i class="bx bx-chevron-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
  data() {
    return {
      services: [
        {
          id: 1,
          icon: "flaticon-consultant",
          title: "IT Consulting",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 2,
          icon: "flaticon-consulting",
          title: "Cloud Computing",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 3,
          icon: "flaticon-web-development",
          title: "Web Development",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 4,
          icon: "flaticon-stats",
          title: "Business Reform",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
      ],
    };
  },
};
</script>