<template>
  <div class="blog-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="blog-article">
            <div class="blog-article-img">
              <img
                src="../../assets/images/blog/blog-details.jpg"
                alt="Images"
              />
              <div class="blog-article-tag">
                <h3>04 March</h3>
              </div>
            </div>

            <div class="blog-article-title">
              <ul>
                <li><i class="bx bxs-user"></i> By Admin</li>
                <li><i class="bx bx-show-alt"></i>322 View</li>
                <li><i class="bx bxs-conversation"></i>2 Comments</li>
              </ul>
              <h2>10 Ways to Get Efficient Result and Benefits</h2>
            </div>

            <div class="article-content">
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. cu sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies ne, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet n, vu eget, arcu. In enim justo, rhoncus
                ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu
                pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus
                elementum semper nisi. Aenean vulputate eleifend tellus. Aen li,
                porttitor eu, consequat vitae, eleifend ac, enim.
              </p>

              <p>
                Proin gravida nibh vel velit auctor aliquet. Aenean
                sollicitudin, lorem quis bibendum auctor, nisi elit consequat
                ipsum. gravida nibh vel velit auctor aliquet. Aenean
                sollicitudin, lorem quis bibendum auctor, Proin gravida nibh vel
                velit nisi elit consequat ipsum.Proin gravida nibh vel velit
                auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                nisi elit consequat ipsum. Proin gravida nibh vel velit.
              </p>

              <blockquote class="blockquote">
                <p>
                  Proin gravida nibh vel velit auctor aliquet. Aenean
                  sollicitudin, lorem quis bibendum auctor, nisi elit consequat
                  ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean
                  sollicitudin, lorem quis bibendum auctor, Proin gravida nibh
                  vel velit nisi elit.
                </p>
                <span>- Albedin Simanth</span>
                <i class="bx bxs-quote-alt-left"></i>
              </blockquote>

              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. cu sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Donec quam felis, ultricies ne, pellentesque eu, pretium
                quis, sem. Nulla consequat massa quis enim. Donec pede justo,
                fringilla vel, aliquet n, vu eget, arcu. In enim justo, rhoncus
                ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu
                pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus
                elementum semper nisi. Aenean vulputate eleifend tellus. Aen li,
                porttitor eu, consequat vitae, eleifend ac, enim.
              </p>

              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>

            <div class="blog-article-share">
              <div class="row align-items-center">
                <div class="col-lg-7 col-sm-7 col-md-7">
                  <div class="blog-tag">
                    <ul>
                      <li><i class="bx bx-purchase-tag-alt"></i> Tags:</li>
                      <li><router-link to="/blog">Android</router-link></li>
                      <li><router-link to="/blog">Creative</router-link></li>
                      <li><router-link to="/blog">App</router-link></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-5 col-sm-5 col-md-5">
                  <ul class="social-icon">
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/?lang=en" target="_blank">
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/" target="_blank">
                        <i class="bx bxl-linkedin-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i class="bx bxl-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="comments-wrap">
              <div class="comment-title">
                <h3 class="title">Comments (02)</h3>
              </div>

              <ul class="comment-list">
                <li>
                  <img
                    src="../../assets/images/blog/blog-profile1.png"
                    alt="Image"
                  />
                  <h3>Medison Decros</h3>
                  <span>On September 18,2023 at 4.30 pm</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. cu sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridicule us mus. Donec quam felis, ultricies ne,
                    pellentesque.
                  </p>
                  <router-link to="/blog-details"> Reply</router-link>
                </li>

                <li>
                  <img
                    src="../../assets/images/blog/blog-profile2.png"
                    alt="Image"
                  />
                  <h3>Jekson Albin</h3>
                  <span>On September 18,2023 at 4.30 pm</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. cu sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridicule us mus. Donec quam felis, ultricies ne,
                    pellentesque.
                  </p>
                  <router-link to="/blog-details"> Reply</router-link>
                </li>

                <li>
                  <img
                    src="../../assets/images/blog/blog-profile3.png"
                    alt="Image"
                  />
                  <h3>Bentham Debid</h3>
                  <span>On September 18,2023 at 4.30 pm</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. cu sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridicule us mus. Donec quam felis, ultricies ne,
                    pellentesque.
                  </p>
                  <router-link to="/blog-details">Reply</router-link>
                </li>
              </ul>
            </div>

            <div class="comments-form">
              <h3 class="title">Leave A Comment</h3>
              <div class="contact-form">
                <form id="contactForm">
                  <div class="row">
                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <label>Your Name <span>*</span></label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          class="form-control"
                          required
                          data-error="Please enter your name"
                          placeholder="Your Name"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <label>Your Email <span>*</span></label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          class="form-control"
                          required
                          data-error="Please enter your email"
                          placeholder="Your Email"
                        />
                      </div>
                    </div>

                    <div class="col-lg-12 col-sm-12">
                      <div class="form-group">
                        <label>Your website <span>*</span></label>
                        <input
                          type="text"
                          name="websit"
                          class="form-control"
                          required
                          data-error="Your website"
                          placeholder="Your website"
                        />
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Your website <span>*</span></label>
                        <textarea
                          name="message"
                          class="form-control"
                          id="message"
                          cols="30"
                          rows="8"
                          required
                          data-error="Write Your Review"
                          placeholder="Your Review"
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <div class="form-group checkbox-option">
                        <input type="checkbox" id="chb2" />
                        <p>
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </p>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <button
                        type="submit"
                        class="default-btn btn-bg-two border-radius-50"
                      >
                        Post A Comment
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <BlogSideBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogSideBar from "../Common/BlogSideBar";

export default {
  name: "BlogDetails",
  components: {
    BlogSideBar,
  },
};
</script>