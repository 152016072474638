<template>
  <div class="work-process-area-two pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="row justify-content-center">
            <div
              class="col-lg-6 col-sm-6"
              v-for="proces in process"
              :key="proces.id"
            >
              <div class="work-process-card-two">
                <div class="number-title">{{ proces.number }}.</div>
                <h3>{{ proces.title }}</h3>
                <p>{{ proces.shortDesc }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-5">
          <div class="work-process-rightside">
            <div class="section-title">
              <span class="sp-color1">Our Working Process</span>
              <h2>Our Services Will Help You to Grow Your Business</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                pharetra risus turpis, non auctor risus dignissim ut. Integer
                porttitor libero id ante elementum imperdiet. Ut pulvinar, risus
                at egestas pharetra, massa lorem hendrerit neque, ut ultricies
                nulla.
              </p>
            </div>
            <router-link
              to="/contact"
              class="default-btn btn-bg-two border-radius-5 text-center"
            >
              Get A Quote
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurWorkingProcess",
  data() {
    return {
      process: [
        {
          id: 1,
          title: "Discovery",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "01",
        },
        {
          id: 2,
          title: "Planning",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "02",
        },
        {
          id: 3,
          title: "Execute",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "03",
        },
        {
          id: 4,
          title: "Deliver",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "04",
        },
      ],
    };
  },
};
</script>