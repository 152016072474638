<template>
  <div class="about-area about-bg2 pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="about-img-5">
            <img
              src="../../assets/images/about/about-img5.png"
              alt="About Images"
            />
          </div>
        </div>

        <div class="col-lg-6">
          <div class="about-content-3 ml-20">
            <div class="section-title">
              <span class="sp-color2">About Your Company</span>
              <h2>We Are Increasing Business With Promising EdTech and It Services</h2>
              <p>
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio
                sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan
                ipsum auctor a ornare odio.
              </p>
            </div>
            <h3>
              We Have 12+ Years Of Experience. We Offer It Solutions , Digital
              Technology Service
            </h3>
            <div class="all-skill-bar">
              <div
                class="skill-bar"
                v-for="(skill, index) in skills"
                :key="index"
              >
                <h4 class="progress-title-holder clearfix">
                  <span class="progress-title">{{ skill.title }}</span>
                  <span class="progress-number-wrapper">
                    <span
                      class="progress-number-mark"
                      :style="{ left: skill.percentage + '%' }"
                    >
                      <span class="percent">{{ skill.percentage }}%</span>
                    </span>
                  </span>
                </h4>
                <div class="progress-content-outter">
                  <div
                    class="progress-content"
                    :style="{ width: skill.percentage + '%' }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutOurCompany",
  data() {
    return {
      skills: [
        {
          id: 1,
          percentage: 90,
          title: "Analytics",
        },
        {
          id: 2,
          percentage: 80,
          title: "Solutions",
        },
      ],
    };
  },
};
</script>