<template>
  <div class="about-area ptb-100">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6">
          <div class="about-play">
            <img
              src="../../assets/images/about/about-img1.jpg"
              alt="About Images"
            />
            <div class="about-play-content">
              <span>Watch Our Intro Video</span>
              <h2>Perfect Solution for IT Services!</h2>
              <div class="play-on-area">
                <div
                  class="play-on"
                  style="cursor: pointer"
                  v-on:click="isPopupMethod(isPopup)"
                >
                  <i class="bx bx-play"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="about-content ml-25">
            <div class="section-title">
              <span class="sp-color2">12 Years of Experience</span>
              <h2>Right Partner for Software Innovation</h2>
              <p>
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio
                sit amet.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <ul class="about-list text-start">
                  <li>
                    <i class="bx bxs-check-circle"></i>Cost of Supplies and
                    Equipment
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Bribed Autor Nisi Elit
                    Volume
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Cost of Supplies and
                    Equipment
                  </li>
                </ul>
              </div>

              <div class="col-lg-6 col-md-6">
                <ul class="about-list text-start about-list-2">
                  <li>
                    <i class="bx bxs-check-circle"></i>Change the Volume of
                    Expected
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Easy to Customer Services
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Good Quality Products
                    Services
                  </li>
                </ul>
              </div>
            </div>
            <p class="about-content-text">
              Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
              consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit
              amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum
              velit.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SoftwareInnovation",
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>