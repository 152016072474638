<template>
  <div class="faq-area ptb-100">
    <div class="container">
      <div
        class="faq-accordion accordion"
        id="faqAccordion"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <div class="section-title text-center">
          <h2>Frequently Asked Questions</h2>
          <p class="margin-auto">
            We are the agency who always gives you a priority on the free of
            question and you can easily make a question on the bunch.
          </p>
        </div>

        <div class="accordion pt-45">
          <div class="accordion-item">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              What is a Managed Security Services?
              <i class="bx bx-minus"></i>
            </button>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p class="margin-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  at diam leo. Mauris a ante placerat, dignissim orci eget,
                  viverra ante. Mauris ornare pellentesque augue.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              What is a Data Analysis?
              <i class="bx bx-minus"></i>
            </button>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p class="margin-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  at diam leo. Mauris a ante placerat, dignissim orci eget,
                  viverra ante. Mauris ornare pellentesque augue.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              How Can Make Secure My Website?
              <i class="bx bx-minus"></i>
            </button>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p class="margin-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  at diam leo. Mauris a ante placerat, dignissim orci eget,
                  viverra ante. Mauris ornare pellentesque augue.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              What is a Infrastructure?
              <i class="bx bx-minus"></i>
            </button>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p class="margin-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  at diam leo. Mauris a ante placerat, dignissim orci eget,
                  viverra ante. Mauris ornare pellentesque augue.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              How Can We Help Your Business?
              <i class="bx bx-minus"></i>
            </button>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p class="margin-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  at diam leo. Mauris a ante placerat, dignissim orci eget,
                  viverra ante. Mauris ornare pellentesque augue.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Why It Staff Management?
              <i class="bx bx-minus"></i>
            </button>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p class="margin-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  at diam leo. Mauris a ante placerat, dignissim orci eget,
                  viverra ante. Mauris ornare pellentesque augue.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              How Working Process Is Simplified?
              <i class="bx bx-minus"></i>
            </button>
            <div
              id="collapseSeven"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p class="margin-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  at diam leo. Mauris a ante placerat, dignissim orci eget,
                  viverra ante. Mauris ornare pellentesque augue.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              Product Engineering & Services?
              <i class="bx bx-minus"></i>
            </button>
            <div
              id="collapseEight"
              class="accordion-collapse collapse"
              data-bs-parent="#faqAccordion"
            >
              <div class="accordion-body">
                <p class="margin-auto">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  at diam leo. Mauris a ante placerat, dignissim orci eget,
                  viverra ante. Mauris ornare pellentesque augue.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqAccordion",
};
</script>

<style lang="scss" scoped>
.faq-accordion {
  max-width: 870px;
  margin: {
    left: auto;
    right: auto;
  }
  .accordion-item {
    box-shadow: 0px 0px 59px 19px rgba(0, 0, 0, 0.03);
    background-color: var(--whiteColor);
    color: var(--blackColor);
    margin-bottom: 20px;
    border-radius: 10px;
    border: none;

    .accordion-button {
      display: block;
      text-align: start;
      box-shadow: unset;
      letter-spacing: 0.05em;
      color: var(--blackColor);
      border-radius: 10px !important;
      background-color: var(--whiteColor);
      font: {
        size: 20px;
        weight: 600;
      }
      padding: {
        top: 25px;
        left: 40px;
        right: 65px;
        bottom: 25px;
      }
      &::after {
        display: none;
      }
      i {
        top: 50%;
        right: 40px;
        content: "\ebc0";
        position: absolute;
        transform: translateY(-50%);
        transition: var(--transition);
      }
      &.collapsed {
        i {
          &::before {
            content: "\ebc0";
          }
        }
      }
    }
    .accordion-body {
      padding: 25px 40px;
      border-top: 1px solid #eaeaea;

      p {
        strong {
          color: var(--blackColor);
          font-weight: 600;
        }
        a {
          color: var(--primaryColor);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .faq-accordion {
    max-width: 100%;

    .accordion-item {
      margin-bottom: 15px;
      border-radius: 5px;

      .accordion-button {
        border-radius: 5px !important;
        font-size: 15px;
        padding: {
          top: 15px;
          left: 15px;
          right: 35px;
          bottom: 15px;
        }
        &::before {
          right: 15px;
        }
      }
      .accordion-body {
        padding: 15px;
      }
    }
  }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-accordion {
    max-width: 100%;

    .accordion-item {
      .accordion-button {
        font-size: 18px;
        padding: {
          top: 18px;
          left: 25px;
          right: 55px;
          bottom: 18px;
        }
        &::before {
          right: 25px;
        }
      }
      .accordion-body {
        padding: 20px 25px;

        p {
          font-size: 15px;
        }
      }
    }
  }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-accordion {
    .accordion-item {
      .accordion-button {
        font-size: 19px;
      }
    }
  }
}
</style>