<template>
  <header class="top-header top-header-bg">
    <div :class="cFluid">
      <div :class="className">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-6">
            <div class="top-head-left">
              <div class="top-contact">
                <h3>
                  Support By :
                  <a href="tel:+1(214)-431-5175">+1 (214) 431-5175</a>
                </h3>
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-md-6">
            <div class="top-header-right">
              <div class="top-header-social">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=100069821734388" target="_blank">
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/home?logout=1691513850044/" target="_blank">
                      <i class="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/lucreskill-tech/?viewAsMember=true" target="_blank">
                      <i class="bx bxl-linkedin-square"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i class="bx bxl-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="language-list">
                <!--<select class="language-list-item">
                  <option>English</option>
                  <option>العربيّة</option>
                  <option>Deutsch</option>
                  <option>Português</option>
                  <option>简体中文</option>
                </select>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TopHeader",
  props: ["cFluid", "className"],
};
</script>