<template>
  <TopHeaderTwo />
  <MainNavbar className="container" />
  <MainBanner />
  <OurWorkingProcess />
  <AboutOurCompany />
  <OurServices />
  <TechnologyIndex class="technology-area-five" />
  <OurTeam />
  <CaseStudy />
  <LatestBlog />
  <FooterTwo />
</template>

<script>
import TopHeaderTwo from "../Layouts/TopHeaderTwo";
import MainNavbar from "../Layouts/MainNavbar";
import MainBanner from "../HomeSix/MainBanner";
import OurWorkingProcess from "../HomeSix/OurWorkingProcess";
import AboutOurCompany from "../HomeSix/AboutOurCompany";
import OurServices from "../HomeSix/OurServices";
import TechnologyIndex from "../Common/TechnologyIndex";
import OurTeam from "../HomeSix/OurTeam";
import CaseStudy from "../HomeSix/CaseStudy";
import LatestBlog from "../HomeSix/LatestBlog";
import FooterTwo from "../Layouts/FooterTwo";

export default {
  name: "HomeSixPage",
  components: {
    TopHeaderTwo,
    MainNavbar,
    MainBanner,
    OurWorkingProcess,
    AboutOurCompany,
    OurServices,
    TechnologyIndex,
    OurTeam,
    CaseStudy,
    LatestBlog,
    FooterTwo,
  },
};
</script>