<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <PageTitle pageTitle="Educational K12 Development" mainTitle="Educational / K12 Development" />
  <K12EducationDetails />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import PageTitle from "../Common/PageTitle";
import K12EducationDetails from "../K12EducationDetails/K12EducationDetails";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "K12EducationPage",
  components: {
    TopHeader,
    MainNavbar,
    PageTitle,
    K12EducationDetails,
    MainFooter,
  },
};
</script>