<template>
  <footer class="footer-area footer-bg2">
    <div class="container">
      <div class="footer-top pt-100 pb-70">
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <div class="footer-widget">
              <div class="footer-logo">
                <router-link to="/">
                  <img
                    src="../../assets/images/logos/lstlogo-2.png"
                    alt="Images"
                  />
                </router-link>
              </div>
              <p>
                Proin gravida nibh vel velit auctor aliquet. Aenean
                sollicitudin, lorem quis bibendum auct.Aenean, lorem quis
                bibendum auct. Aenean sollicitudin lorem.
              </p>
              <div class="social-link">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <i class="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i class="bx bxl-linkedin-square"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i class="bx bxl-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-sm-6">
            <div class="footer-widget pl-2">
              <h3>Services</h3>
              <ul class="footer-list">
                <li>
                  <router-link to="/service-details" target="_blank">
                    <i class="bx bx-chevron-right"></i>
                    IT Consultancy
                  </router-link>
                </li>
                <li>
                  <router-link to="/service-details" target="_blank">
                    <i class="bx bx-chevron-right"></i>
                    Business Solution
                  </router-link>
                </li>
                <li>
                  <router-link to="/service-details" target="_blank">
                    <i class="bx bx-chevron-right"></i>
                    Digital Services
                  </router-link>
                </li>
                <li>
                  <router-link to="/compare" target="_blank">
                    <i class="bx bx-chevron-right"></i>
                    Business Reform
                  </router-link>
                </li>
                <li>
                  <router-link to="/service-details" target="_blank">
                    <i class="bx bx-chevron-right"></i>
                    Web Development
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget pl-5">
              <h3>Our Blog</h3>
              <ul class="footer-blog">
                <li>
                  <router-link to="/blog-details">
                    <img
                      src="../../assets/images/blog/blog-img-footer.jpg"
                      alt="Images"
                    />
                  </router-link>
                  <div class="content">
                    <h3>
                      <router-link to="/blog-details">
                        Product Idea Solution For New Generation
                      </router-link>
                    </h3>
                    <span>04 Dec 2023</span>
                  </div>
                </li>

                <li>
                  <router-link to="/blog-details">
                    <img
                      src="../../assets/images/blog/blog-img-footer2.jpg"
                      alt="Images"
                    />
                  </router-link>
                  <div class="content">
                    <h3>
                      <router-link to="/blog-details">
                        New Device Invention for Digital Platform
                      </router-link>
                    </h3>
                    <span>07 Dec 2023</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3>Quick Contact</h3>
              <ul class="footer-contact-list">
                <li>
                  <i class="bx bxs-map"></i>
                  <div class="content">
                    <router-link to="/contact">
                      124 Virgil A Virginia, CSN <br />
                      Building 201, USA
                    </router-link>
                  </div>
                </li>

                <li>
                  <i class="bx bx-phone-call"></i>
                  <div class="content">
                    <a href="tel:+4498753452435"> +44 9875 3452 435 </a>
                    <br />
                    <a href="tel:+4498755767536"> +44 9875 5767 536 </a>
                  </div>
                </li>

                <li>
                  <i class="bx bx-message"></i>
                  <div class="content">
                    <a href="mailto:info@lucreskilltech.com"> info@lucreskilltech.com </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="copy-right-area">
        <div class="copy-right-text">
          <p>
            Copyright ©
            {{ currentYear }}
            Lucreskill. All Rights Reserved by
            <a href="https://lucreskilltech.com/" target="_blank">Lucreskill Tech</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterTwo",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>