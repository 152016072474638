<template>
  <div class="blog-style-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="row justify-content-center">
            <div class="col-lg-12" v-for="blog in blogs" :key="blog.id">
              <div class="blog-style-card">
                <div class="blog-style-img">
                  <router-link to="/blog-details">
                    <img :src="blog.image" alt="Images" />
                  </router-link>
                  <div class="blog-style-tag">
                    <h3>{{ blog.date }}</h3>
                  </div>
                </div>

                <div class="content">
                  <ul>
                    <li><i class="bx bxs-user"></i> By {{ blog.user }}</li>
                    <li>
                      <i class="bx bx-purchase-tag-alt"></i>{{ blog.tag }}
                    </li>
                  </ul>
                  <h3>
                    <router-link to="/blog-details">
                      {{ blog.title }}
                    </router-link>
                  </h3>
                  <p>{{ blog.desc }}</p>
                  <router-link
                    to="/blog-details"
                    class="default-btn btn-bg-two border-radius-50"
                  >
                    Learn More
                    <i class="bx bx-chevron-right"></i>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 text-center">
              <div class="pagination-area">
                <a href="/blog" class="prev page-numbers">
                  <i class="bx bx-left-arrow-alt"></i>
                </a>

                <span class="page-numbers current" aria-current="page">1</span>
                <a href="/blog" class="page-numbers">2</a>
                <a href="/blog" class="page-numbers">3</a>

                <a href="/blog" class="next page-numbers">
                  <i class="bx bx-right-arrow-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <BlogSideBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogSideBar from "../Common/BlogSideBar";

export default {
  name: "BlogContent",
  components: {
    BlogSideBar,
  },
  data() {
    return {
      blogs: [
        {
          id: 1,
          image: require("../../assets/images/blog/blog-style-img1.jpg"),
          date: "28 March",
          user: "Admin",
          tag: "Business",
          title: "10 Ways To Get Efficient Result and Benefits",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, Proin gravida nibh vel vewwlit nisi elit consequat ipsum.",
        },
        {
          id: 2,
          image: require("../../assets/images/blog/blog-style-img2.jpg"),
          date: "27 March",
          user: "Admin",
          tag: "Digital",
          title: "New Device Invention for Digital Platform",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, Proin gravida nibh vel vewwlit nisi elit consequat ipsum.",
        },
        {
          id: 3,
          image: require("../../assets/images/blog/blog-style-img3.jpg"),
          date: "25 March",
          user: "Admin",
          tag: "App",
          title:
            "5 App that Really Hack and Help you to Make Your Phone More Easy",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, Proin gravida nibh vel vewwlit nisi elit consequat ipsum.",
        },
        {
          id: 4,
          image: require("../../assets/images/blog/blog-style-img4.jpg"),
          date: "24 March",
          user: "Admin",
          tag: "Product",
          title: "Product Idea Solution for new Generation",
          desc: "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, Proin gravida nibh vel vewwlit nisi elit consequat ipsum.",
        },
      ],
    };
  },
};
</script>