<template>
  <div class="services-style-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Services</span>
        <h2>We Provide a Wide Variety of EdTech and IT Services</h2>
        <p class="margin-auto">
          Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat
          ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh
          vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam
          nec
        </p>
      </div>
      <div class="row pt-45 justify-content-center">
        <div
          class="col-lg-3 col-sm-6"
          v-for="service in services"
          :key="service.id"
        >
          <div class="services-card services-style-bg">
            <i :class="service.icon"></i>
            <h3>
              <router-link to="/service-details">{{
                service.title
              }}</router-link>
            </h3>
            <p>{{ service.shortDesc }}</p>
            <router-link to="/service-details" class="learn-btn">
              Learn More
              <i class="bx bx-chevron-right"></i>
            </router-link>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 text-center">
          <div class="pagination-area">
            <a href="/services-two" class="prev page-numbers">
              <i class="bx bx-left-arrow-alt"></i>
            </a>

            <span class="page-numbers current" aria-current="page">1</span>
            <a href="/services-two" class="page-numbers">2</a>
            <a href="/services-two" class="page-numbers">3</a>

            <a href="/services-two" class="next page-numbers">
              <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesContent",
  data() {
    return {
      services: [
        {
          id: 1,
          icon: "flaticon-consultant",
          title: "IT Consulting",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 2,
          icon: "flaticon-consulting",
          title: "Cloud Computing",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 3,
          icon: "flaticon-web-development",
          title: "Web Development",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 4,
          icon: "flaticon-stats",
          title: "Business Reform",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 5,
          icon: "flaticon-structure",
          title: "Infrastructure",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 6,
          icon: "flaticon-data-analytics",
          title: "Data Analysis",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 7,
          icon: "flaticon-computer",
          title: "Manage IT Service",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
        {
          id: 8,
          icon: "flaticon-cyber-security",
          title: "Business Security",
          shortDesc:
            "Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendauctor nisi el.",
        },
      ],
    };
  },
};
</script>