<template>
  <div class="team-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Team</span>
        <h2>Our Team Members</h2>
      </div>
      <div class="row pt-45 justify-content-center">
        <div class="col-lg-4 col-md-6" v-for="team in teams" :key="team.id">
          <div class="team-card">
            <img :src="team.image" alt="Team Images" />
            <ul class="social-link">
              <li>
                <a href="https://www.facebook.com/" target="_blank">
                  <i class="bx bxl-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <i class="bx bxl-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/" target="_blank">
                  <i class="bx bxl-linkedin-square"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <i class="bx bxl-instagram"></i>
                </a>
              </li>
            </ul>
            <div class="content">
              <h3>{{ team.name }}</h3>
              <span>{{ team.designation }}</span>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 text-center">
          <div class="pagination-area">
            <a href="/team" class="prev page-numbers">
              <i class="bx bx-left-arrow-alt"></i>
            </a>

            <span class="page-numbers current" aria-current="page">1</span>
            <a href="/team" class="page-numbers">2</a>
            <a href="/team" class="page-numbers">3</a>

            <a href="/team" class="next page-numbers">
              <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
  data() {
    return {
      teams: [
        {
          id: 1,
          image: require("../../assets/images/team/team-img6.jpg"),
          name: "Amol Bandal",
          designation: "Director",
        },
        {
          id: 1,
          image: require("../../assets/images/team/team-img2.jpg"),
          name: "Bhagyashri Shinde",
          designation: "Director",
        },
        {
          id: 3,
          image: require("../../assets/images/team/team-img3.jpg"),
          name: "Vijay Lipton",
          designation: "Business Development Executive",
        },
        {
          id: 4,
          image: require("../../assets/images/team/Nitajain.jpg"),
          name: "Nita Jain",
          designation: "Project Co-ordinator",
        },
        {
          id: 5,
          image: require("../../assets/images/team/team-img4.jpg"),
          name: "Pravin Kokane",
          designation: "Project Manager",
        },
        {
          id: 6,
          image: require("../../assets/images/team/team-img5.jpg"),
          name: "Neha Jain",
          designation: "Head-IDE",
        },
        {
          id: 7,
          image: require("../../assets/images/team/Soundarrajan.png"),
          name: "Soundarrajan",
          designation: "Head-Animation",
        },
        {
          id: 8,
          image: require("../../assets/images/team/team-img5.jpg"),
          name: "Vrushali Desai",
          designation: "Head-ELearning",
        },
        {
          id: 9,
          image: require("../../assets/images/team/Mithilesh.jpg"),
          name: "Mithilesh Kumar",
          designation: "Lead Animator",
        },
      ],
    };
  },
};
</script>