<template>
  <div class="side-bar-area">
    <div class="search-widget">
      <form class="search-form">
        <input type="search" class="form-control" placeholder="Search..." />
        <button type="submit">
          <i class="bx bx-search"></i>
        </button>
      </form>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Blog Categories</h3>
      <div class="side-bar-categories">
        <ul>
          <li>
            <div class="line-circle"></div>
            <router-link to="/blog-details">
              IT Services<span>[70]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/blog-details">
              Business<span>[24]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/blog-details">
              Creative Invention<span>[08]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/blog-details">
              Technology <span>[17]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/blog-details">
              IT Consulting <span>[20]</span>
            </router-link>
          </li>
          <li>
            <div class="line-circle"></div>
            <router-link to="/blog-details">
              Marketing Growth <span>[13]</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Latest Blog</h3>
      <div class="widget-popular-post">
        <div class="item">
          <router-link to="/blog-details" class="thumb">
            <span class="full-image cover bg1" role="img"></span>
          </router-link>
          <div class="info">
            <h4 class="title-text">
              <router-link to="/blog-details">
                10 Ways to Get Efficient Result & Benefits
              </router-link>
            </h4>
            <p>Nov 05, 2023</p>
          </div>
        </div>

        <div class="item">
          <router-link to="/blog-details" class="thumb">
            <span class="full-image cover bg2" role="img"></span>
          </router-link>
          <div class="info">
            <h4 class="title-text">
              <router-link to="/blog-details">
                New Device Invention for Digital Platform
              </router-link>
            </h4>
            <p>13 October, 2023</p>
          </div>
        </div>

        <div class="item">
          <router-link to="/blog-details" class="thumb">
            <span class="full-image cover bg3" role="img"></span>
          </router-link>
          <div class="info">
            <h4 class="title-text">
              <router-link to="/blog-details">
                Idea For New 5 App Design
              </router-link>
            </h4>
            <p>17 October, 2023</p>
          </div>
        </div>

        <div class="item">
          <router-link to="/blog-details" class="thumb">
            <span class="full-image cover bg4" role="img"></span>
          </router-link>
          <div class="info">
            <h4 class="title-text">
              <router-link to="/blog-details">
                Product Idea Solution For New Generation
              </router-link>
            </h4>
            <p>17 October, 2023</p>
          </div>
        </div>
      </div>
    </div>

    <div class="side-bar-widget">
      <h3 class="title">Tag Cloud</h3>
      <ul class="side-bar-widget-tag">
        <li><router-link to="/blog">Android</router-link></li>
        <li><router-link to="/blog">Creative</router-link></li>
        <li><router-link to="/blog">App</router-link></li>
        <li><router-link to="/blog">IOS</router-link></li>
        <li><router-link to="/blog">Business</router-link></li>
        <li><router-link to="/blog">Consulting</router-link></li>
      </ul>
    </div>

    <div class="side-bar-widget">
      <h3 class="title mb-0">Gallery</h3>
      <ul class="blog-gallery">
        <li>
          <router-link to="/blog-details">
            <img
              src="../../assets/images/blog/blog-small-img1.jpg"
              alt="image"
            />
          </router-link>
        </li>
        <li>
          <router-link to="/blog-details">
            <img
              src="../../assets/images/blog/blog-small-img2.jpg"
              alt="image"
            />
          </router-link>
        </li>
        <li>
          <router-link to="/blog-details">
            <img
              src="../../assets/images/blog/blog-small-img3.jpg"
              alt="image"
            />
          </router-link>
        </li>
        <li>
          <router-link to="/blog-details">
            <img
              src="../../assets/images/blog/blog-small-img4.jpg"
              alt="image"
            />
          </router-link>
        </li>
        <li>
          <router-link to="/blog-details">
            <img
              src="../../assets/images/blog/blog-small-img5.jpg"
              alt="image"
            />
          </router-link>
        </li>
        <li>
          <router-link to="/blog-details">
            <img
              src="../../assets/images/blog/blog-small-img6.jpg"
              alt="image"
            />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogSideBar",
};
</script>