<template>
  <div class="case-study-area-two pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Case Study</span>
        <h2>Introduce Our Projects and Check Recent Work</h2>
      </div>

      <div class="row justify-content-center pt-45">
        <div class="col-lg-4 col-sm-6" v-for="item in items" :key="item.id">
          <div class="case-study-item">
            <router-link to="/case-study-details">
              <img :src="item.image" alt="Images" />
            </router-link>
            <div class="content">
              <h3>
                <router-link to="/case-study-details">
                  {{ item.title }}
                </router-link>
              </h3>
              <ul>
                <li v-for="tag in item.tags" :key="tag.id">
                  <router-link to="/case-study">{{ tag.tag }}</router-link>
                </li>
              </ul>
              <router-link to="/case-study-details" class="more-btn">
                <i class="bx bx-right-arrow-alt"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStudy",
  data() {
    return {
      items: [
        {
          id: 1,
          image: require("../../assets/images/case-study/case-study1.jpg"),
          title: "Business Solution",
          tags: [
            {
              id: 1,
              tag: "Business",
            },
            {
              id: 2,
              tag: "Planing",
            },
          ],
        },
        {
          id: 2,
          image: require("../../assets/images/case-study/case-study2.jpg"),
          title: "Digital Marketing",
          tags: [
            {
              id: 1,
              tag: "Digital",
            },
            {
              id: 2,
              tag: "Marketing",
            },
          ],
        },
        {
          id: 3,
          image: require("../../assets/images/case-study/case-study3.jpg"),
          title: "Strategic Planing",
          tags: [
            {
              id: 1,
              tag: "Strategic",
            },
            {
              id: 2,
              tag: "Planing",
            },
          ],
        },
      ],
    };
  },
};
</script>