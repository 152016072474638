<template>
  <section class="services-area-four pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Services</span>
        <h2>We Provide Truly EdTech and IT Solutions</h2>
        <p class="margin-auto">
          Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat
          ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh
          vulputate cursus a sit amet mauris Morbi accumsan ipsum velit.
        </p>
      </div>

      <div class="row justify-content-center align-items-center pt-45">
        <div
          class="col-lg-4 col-md-6"
          v-for="service in services"
          :key="service.id"
        >
          <div class="services-card services-card-color-bg2">
            <router-link to="/service-details">
              <img :src="service.image" alt="services" />
            </router-link>
            <h3>
              <router-link to="/service-details">{{
                service.title
              }}</router-link>
            </h3>
            <p>{{ service.shortDesc }}</p>
            <router-link to="/service-details" class="learn-btn">
              Learn More
              <i class="bx bx-chevron-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurServices",
  data() {
    return {
      services: [
        {
          id: 1,
          image: require("../../assets/images/services/services-img7.jpg"),
          title: "IT Consulting",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at ligula eget lectus consequat volutpat.",
        },
        {
          id: 2,
          image: require("../../assets/images/services/services-img8.jpg"),
          title: "Cloud Computing",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at ligula eget lectus consequat volutpat.",
        },
        {
          id: 3,
          image: require("../../assets/images/services/services-img9.jpg"),
          title: "Web Development",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at ligula eget lectus consequat volutpat.",
        },
      ],
    };
  },
};
</script>