<template>
  <div class="banner-five-area">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="banner-five-content">
            <span>TECHNOLOGY & IT SOLUTIONS</span>
            <h1>
              It Solutions , <b>Digital</b> Technology & Services All Around the
              World
            </h1>
            <p>
              Aenean Sollicitudin, Lorem quis cursus a auctor, nisi elit
              consequat ipsum, nec sagittis sem nibh id elit duis sed odio sit
              amet nibh.
            </p>
            <router-link
              to="/contact"
              class="default-btn btn-bg-two border-radius-50"
            >
              Get A Quote
            </router-link>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="banner-five-img">
            <img src="../../assets/images/home-five-img.png" alt="Images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>