<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/app-development.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>Application Services</h2>
              <p>                
                Lucreskill application development services enables organizations to reduce product life cycles, while improving time to market and increasing return on investment. We drive relevant innovation and transformation using latest trends and emerging technologies. Our digital technologies use key business KPIs to drive rapid innovation in how enterprises serve end clients. We use proven mobile-enabled, DevOps and microservices-oriented architecture coupled with agile development to assist enterprises.
              </p>
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>We're Mission Driven.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Consulting services.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Focused Towards Project Objectives.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Holistic Approach.
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Project Management at Its Best.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Professional Team of Software Developers.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Quality Assurance.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Transparent Communication.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="case-article-content">
              <h2>Scale Your Organization’s Growth With Custom Software Development Solutions</h2>
                <p>We provide multi-platform and easily-deployable digital solutions for businesses, educational institutions, and public organizations.</p>
                <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Application Development
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Java Full Stack Developement
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Microsoft Full Stack Developement
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Back-End Development
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>API Development
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Azure cloud computing
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Mobile App Development
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Desktop App Development
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>ECommerce Portal Development
                    </li> 
                    <li>
                      <i class="bx bxs-check-circle"></i>Data Management
                    </li>                    
                  </ul>
                </div>
              </div>
            </div>
            <div class="case-article-content">
              <p>                
                With a relentless commitment to innovation and a proven track record of success, we take pride in being your strategic partner in the digital transformation journey.</p>
              <p>Discover how Lucreskill can elevate your business through custom software solutions. Whether you're a startup looking to make a mark or an established enterprise seeking digital transformation, we are here to turn your vision into reality.</p>
              </div>
            <div class="case-play-btn">
              <a
                class="case-play"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>

            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucerskill is often at the forefront of adopting new learning technologies and methodologies, 
                ensuring that your organization benefits from the latest advancements by assuring commitment
                to keep content current and providing ongoing technical and customer support. We are adaptive 
                in learning techniques to tailor content to individual learner needs, providing a more 
                personalized experience with our cost effective solution compared to traditional in-person training or education.
                Elearning can lead to increased job satisfaction and learner satisfaction, as 
                it empowers individuals to learn at their own pace and in their preferred style.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <p>
                We put the customer at the center of a multidisciplinary, agile approach: alignment of strategy, design, and technology around the customer journey, enabling innovation of the product service experience through iterative, incremental activations and deliver continual enterprise transformation at speed and at scale.
              </p>
            </div>

            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Our application services help you reimagine your application landscape, with speed and agility, while continuously innovating to realize business value.
              </p>
              <h2>We provide End-to-end application services</h2>
                <div class="case-article-content">
                  <div class="col-lg-12 col-md-12">
                    <ul class="case-article-list case-article-ls">
                      <li>
                        <i class="bx bxs-check-circle"></i>Application Development
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Application Modernization
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Agile transformation
                      </li>
                      <li>
                        <i class="bx bxs-check-circle"></i>Intelligent automation
                      </li> 
                      <li>
                        <i class="bx bxs-check-circle"></i>Quality engineering
                      </li>                    
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "AnimationVideoDev",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>