<template>
  <div class="services-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span>Our Services</span>
        <h2>We Provide a Wide Variety of EdTech and IT Services</h2>
        <p class="margin-auto">
          Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat
          ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh
          vulputate cursus a sit amet mauris Morbi accumsan ipsum velit.
        </p>
      </div>
      <div class="row pt-45 justify-content-center">
        <div
          class="col-lg-3 col-sm-6"
          v-for="service in services"
          :key="service.id"
        >
          <div class="services-card">
            <i :class="service.icon"></i>
            <h3>
              <router-link to="/service-details">{{
                service.title
              }}</router-link>
            </h3>
            <p>{{ service.shortDesc }}</p>
            <router-link to="/service-details" class="learn-btn">
              Learn More
              <i class="bx bx-chevron-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="service-shape">
      <img src="../../assets/images/shape/service-shape1.png" alt="Images" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
  data() {
    return {
      services: [
        {
          id: 1,
          icon: "flaticon-stats",
          title: "Enterprise Training Development",
          shortDesc:
            "We offer highly customized learning solutions to suit your training needs and preferences. Our dedicated teams assess and understand your organizational culture, evaluate the content and align themselves with your training objectives, in order to achieve the highest quality of training materials. By combining interesting interface, text, interactive graphics and media, with training strategies, we create consistent and comprehensive mobile-first programs which can help change the way your employees learn. With smart designs and interactive learning resources, these eLearning solutions will enrich your enterprise training programs.",
        },
        {
          id: 2,
          icon: "flaticon-consultant",
          title: "Animation & Video Development",
          shortDesc:
            "Nothing explains better than an audio-visual rendition. And, with growth of digital marketing, video content has grown to be the most powerful tool to reach out to your current and potential audience. Our team of video producers and editors use their unique storytelling skills to create animated logos, motion graphics, on-screen characters, animated short films, and infographics. We help you create videos for marketers, educators, corporates, and startups, which reflect your organization’s persona and gets across your message, instructions, or information in a comprehensive, yet interesting manner.",
        },
        {
          id: 3,
          icon: "flaticon-consulting",
          title: "Educational / K12 Development",
          shortDesc:
            "To suit the needs of the changing academic content requirement, we develop Smart Class content and training materials for K-12 students. Our expert coaches and mentors, trained in various faculties design the most intuitive programs to help strengthen the linguistic, logical, mathematical, interpersonal, and naturalistic skills of modern students. We also provide eBook solutions from editing and proofreading to publishing guidance. By harnessing the power of educational tech, we bring a difference to the education of every child and prepare them for a better future.",
        },
        {
          id: 4,
          icon: "flaticon-web-development",
          title: "Flash to HTML5 Conversion",
          shortDesc:
            "When an organization want to convert their Flash based courses to HTML5, the major constrain will be the budget. Migration is an overhead cost to the organization, especially if the courses are in high number. Considering the budget and the timeline, here are different approaches we follow for converting from Flash to HTML5.",
        },        
      ],
    };
  },
};
</script>