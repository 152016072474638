
<template>
  <div class="security-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">ED Tech, IT Security & Computing</span>
        <h2>
          Searching for a Solution! We Provide Truly Prominent ED Tech and IT Solutions
        </h2>
      </div>
      <div class="row pt-45">
        <div class="col-lg-4 col-sm-6" v-for="card in cards" :key="card.id">
          <div class="security-card">
            <i :class="card.icon"></i>
            <h3>
              <router-link to="/case-study-details">{{
                card.title
              }}</router-link>
            </h3>
            <p>{{ card.shortDesc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ITSecurity",
  data() {
    return {
      cards: [
        {
          id: 1,
          icon: "flaticon-effective",
          title: "EdTech Product Analysis",
          shortDesc:
            "Analyzing an eLearning product is essential to determine its suitability for educational purposes, whether in a school, university, or corporate training environment for evaluating its effectiveness, identifying areas for improvement, and ensuring it aligns with quality and relevance of the educational content or training goals with pedagogical approach. ",
        },
        {
          id: 2,
          icon: "flaticon-computer",
          title: "Manage IT Service",
          shortDesc:
            "Successful IT service management involves a holistic approach, focusing on delivering quality services, minimizing disruptions, and adapting to evolving business needs. By Aligning best practises for IT services with clients objectives to ensure that technology supports business goals and strategies to achieve success and processes for improvement opportunities.",
        },
        {
          id: 3,
          icon: "flaticon-cyber-security",
          title: "Business Security",
          shortDesc:
            "Lucreskill secures clients for their business with combination of expertise, trust-building, effective marketing, and a strong commitment to providing tailored, high-quality solutions by demonstrating dedication to their security needs and delivering on promises with safeguarding a company's assets, data, employees, and operations.",
        },      
        {
          id: 4,
          icon: "flaticon-implement",
          title: "Analytic Solution",
          shortDesc:
            "Organizations can select from a variety of analytics solutions to address their specific needs and objectives, whether they want to gain business insights, improve operational efficiency, enhance customer experiences, or make informed strategic decisions. Cchoice of the right solution often depends on the type, volume of data, and complexity of the analysis with specific industry or domain.",
        },
        {
          id: 5,
          icon: "flaticon-consulting",
          title: "Finest Quality",
          shortDesc:
            "The finest quality is achieved by several key factors such as Pedagogical Effectiveness, Engaging and interactive along with customization and adaptability with data driven insights with content variety, long term viability that contribute to effective learning and teaching experiences by accommodating different languages and cultural contexts.",
        },
        {
          id: 6,
          icon: "flaticon-consultant",
          title: "Risk Management",
          shortDesc:
            "Proactively managing risks associated with EdTech, educational institutions or corporate content created will be with safe and secure environment for technology-enhanced learning while ensuring the privacy and well-being of students and staff by assessing Risk and managing Data Security and Privacy by following Compliance and Legal Considerations.",
        },
      ],
    };
  },
};
</script>