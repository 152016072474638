<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/Languages-Translator.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>Course Localization</h2>
              <p>                
                Our language and technology experts have the right cultural knowhow to guide you through content development for respective regions or countries. Our competent translators are experienced and certified in their native language skills and offer a vast range of translations for course materials, academic collaterals, so your course is not restricted by language and assumes global acceptance. We ensure strict review and quality checks of every translated material so you are assured of high-end deliverables.
              </p>              
            </div>

            <div class="case-article-content">
              <h2>Software Localization</h2>
              <p>                
                Our software localization solutions involve global software design and development, defined in the respective language and subject of regional relevance. With their native skills, coupled with advanced technical knowledge, our localization experts ensure increased awareness among a global crowd.</p>
                <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Localization of user interface
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Online assistance and printed documentation
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Comprehensive and customizable products
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Use of Translation Memory Technologies
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Product & Functionality Testing
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Bi Functionality Testing
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Software internalization
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Linguistic Review
                    </li> 
                    <li>
                      <i class="bx bxs-check-circle"></i>Quality control
                    </li>                    
                  </ul>
                </div>
                <div class="case-article-another">            
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
            </div>            
            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucerskill is often at the forefront of adopting new learning technologies and methodologies, 
                ensuring that your organization benefits from the latest advancements by assuring commitment 
                to keep content current and providing ongoing technical and customer support. We are adaptive 
                in learning techniques to tailor content to individual learner needs, providing a more 
                personalized experience with our cost effective solution compared to traditional in-person 
                training or education. Elearning can lead to increased job satisfaction and learner 
                satisfaction, as it empowers individuals to learn at their own pace and in their preferred 
                style.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <p>
                eLearning offers numerous benefits for education and trainings in all aspects. 
                These advantages can enhance the learning experience for students, 
                teachers, and schools along with access to a vast array of educational resources, 
                including online textbooks, interactive simulations, virtual labs, and multimedia 
                content, enriching the learning experience.
              </p>
            </div>
            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through 
                creativity, originality, and customization to meet the 
                needs of specific audience. By focusing on these aspects 
                at each stage of development, we are creating eLearning 
                materials that stand out and effectively engage learners.
              </p>
            </div>
            </div>
          </div>            
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "AnimationVideoDev",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>