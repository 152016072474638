<template>
  <div class="map-area">
    <div class="container-fluid m-0 p-0">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d946.2173745564318!2d73.86769639550445!3d18.44423350238914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb49ef614367%3A0x1dc04341678fb9d5!2sKanchanpushpa%20Hsg%20Complex!5e0!3m2!1sen!2sin!4v1697170374071!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactMap",
};
</script>