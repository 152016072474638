<template>
  <TopHeader className="container" />
  <MainNavbar className="container" />
  <PageTitle pageTitle="About Us" mainTitle="About Us" />
  <SoftwareInnovation />
  <WhyChooseUs />
  <ITSecurity />
  <BrandLogo className="brand-area-two" />
  <NumbersAreTalking class="pt-100" />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import PageTitle from "../Common/PageTitle";
import SoftwareInnovation from "../About/SoftwareInnovation";
import WhyChooseUs from "../About/WhyChooseUs";
import ITSecurity from "../About/ITSecurity";
import BrandLogo from "../Common/BrandLogo";
import NumbersAreTalking from "../Common/NumbersAreTalking";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "AboutPage",
  components: {
    TopHeader,
    MainNavbar,
    PageTitle,
    SoftwareInnovation,
    WhyChooseUs,
    ITSecurity,
    BrandLogo,
    NumbersAreTalking,
    MainFooter,
  },
};
</script>