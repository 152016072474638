import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVueNext from "bootstrap-vue-next";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import "swiper/css";
import "swiper/css/bundle";

import "./assets/custom.css";

const app = createApp(App).use(router);
app.use(BootstrapVueNext);
app.mount("#app");
