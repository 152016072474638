<template>
  <div class="clients-area-three pt-100">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Clients</span>
        <h2>Our Clients Feedback</h2>
      </div>

      <swiper
        :spaceBetween="30"
        :grabCursor="true"
        :loop="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: true,
        }"
        :navigation="true"
        :modules="modules"
        class="clients-slider-two pt-45"
      >
        <swiper-slide v-for="client in clients" :key="client.id">
          <div class="clients-slider-item">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="clients-slider-img">
                  <img :src="client.image" alt="Images" />
                  <div class="clients-slider-circle"></div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="clients-slider-content">
                  <i class="bx bxs-quote-alt-left"></i>
                  <p>{{ client.desc }}</p>
                  <h3>{{ client.name }}</h3>
                  <span>{{ client.designation }}</span>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation } from "swiper";

export default {
  name: "OurClients",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      clients: [
        {
          id: 1,
          image: require("../../assets/images/clients-img/client-user-img1.png"),
          desc: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at ligula eget lectus consequat volutpat. Donec elit libero, finibus eget scelerisque sed, tempor sit amet erat. Pellentesque tincidunt a purus sit amet ullamcorper. Vestibulum ultrices ligula in pharetra sagittis. Quisque lacinia magna dolor, at tempus sem consequat at.”",
          name: "Jonthon Martin",
          designation: "App Developer",
        },
        {
          id: 2,
          image: require("../../assets/images/clients-img/client-user-img2.png"),
          desc: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at ligula eget lectus consequat volutpat. Donec elit libero, finibus eget scelerisque sed, tempor sit amet erat. Pellentesque tincidunt a purus sit amet ullamcorper. Vestibulum ultrices ligula in pharetra sagittis. Quisque lacinia magna dolor, at tempus sem consequat at.”",
          name: "Alin Decros",
          designation: "Graphic Designer",
        },
        {
          id: 3,
          image: require("../../assets/images/clients-img/client-user-img1.png"),
          desc: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at ligula eget lectus consequat volutpat. Donec elit libero, finibus eget scelerisque sed, tempor sit amet erat. Pellentesque tincidunt a purus sit amet ullamcorper. Vestibulum ultrices ligula in pharetra sagittis. Quisque lacinia magna dolor, at tempus sem consequat at.”",
          name: "Elen Musk",
          designation: "Web Developer",
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay, Navigation],
    };
  },
};
</script>