<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/staffaug.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>On-demand access to learning & development consultants</h2>
              <p>                
                Your success is our success when you hire LucreskillTech consultant. Let us ease you stress and take the task of hiring short- and long-term consultants off your hands, so you can focus elsewhere.</p>
              <h2>Finding the right consultant should not be a headache.</h2>
              <p>We are stacked with industry-leading L&D professionals, so we understand how long it can take to find the right person for the job. There is a reason why LucreskillTech has helped some of the world is top businesses with their projects, and it is because we make it easy for them to get what they need when they need it.</p>
              <h2>EdTech Consultants</h2>   
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Instructional Designers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Learning Experience Designers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>eLearning Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Storyline Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Technical Writers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Visual Designers, 3D Designers, & AR/VR Developers
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Learning Coordinators
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Project Managers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>UX/UI Designers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Learning Strategy Consultants
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Quality Assurance Specialists  
                    </li>
                  </ul>
                </div>
              </div>
              <h2>Application Development</h2>   
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Azure Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>AWS Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Java Full Stack Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Microsoft Technology Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>React Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Angular Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Technical Architects
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Data Scientists
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Full Stack Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>DevOps Engineers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Mobile App Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Appian Developers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Database Administrators  
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Project Managers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Business Analysts
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Automation Test Engineers
                    </li>                  
                  </ul>
                </div>
              </div> 
            </div>     
            <div class="case-article-another">            
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <p>
                  Staff augmentation removes the overhead associated with sourcing, recruiting, training and retaining talent. It can help you meet short and long term technology goals with added flexibility and instant access to a high-quality talent pool.
                </p>
                <div class="case-article-content">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <ul class="case-article-list case-article-rs case-article-ls">
                        <li>
                          <i class="bx bxs-check-circle"></i>Streamline communications
                        </li>
                        <li>
                          <i class="bx bxs-check-circle"></i>Skip recruitment delays
                        </li>
                        <li>
                          <i class="bx bxs-check-circle"></i>Create healthy teams
                        </li>
                        <li>
                          <i class="bx bxs-check-circle"></i>Access high-quality, pre-vetted talent
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> 
            </div>            
            <div class="case-article-content">     
            <h2>Start Your Transformation</h2>
            <p>Staff augmentation is a type of outsourcing service, and we provide our artists or engineers in the form of external hire, usually on a time & materials contract basis.</p>   
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Direct On Hire
                    </li> 
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>On Contract
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through 
                creativity, originality, and customization to meet the 
                needs of specific audience. By focusing on these aspects 
                at each stage of development, we are creating eLearning 
                materials that stand out and effectively engage learners.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "StaffAugDetails",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>