<template>
  <TopHeader cFluid="container-fluid" className="container-max" />
  <MainNavbar
    cFluid="container-fluid"
    className="container-max"
    navSideMt="nav-side-mt"
  />
  <MainBanner />
  <SoftwareInnovation />
  <OurServices className="services-area-two" />
  <OurWorkingProcess />
  <WhyChooseUs />
  <BuildYourWebsite />
  <CallUs callUsImage="call-us/call-us-img2.png" />
  <NumbersAreTalking class="pt-100" />
  <OurClients />
  <LatestBlog />
  <MainFooter />
</template>

<script>
import TopHeader from "../Layouts/TopHeader";
import MainNavbar from "../Layouts/MainNavbar";
import MainBanner from "../HomeTwo/MainBanner";
import SoftwareInnovation from "../HomeTwo/SoftwareInnovation";
import OurServices from "../Common/OurServices";
import OurWorkingProcess from "../HomeTwo/OurWorkingProcess";
import WhyChooseUs from "../HomeTwo/WhyChooseUs";
import BuildYourWebsite from "../HomeTwo/BuildYourWebsite";
import CallUs from "../Common/CallUs";
import NumbersAreTalking from "../Common/NumbersAreTalking";
import OurClients from "../Common/OurClients";
import LatestBlog from "../Common/LatestBlog";
import MainFooter from "../Layouts/MainFooter";

export default {
  name: "HomeTwoPage",
  components: {
    TopHeader,
    MainNavbar,
    MainBanner,
    SoftwareInnovation,
    OurServices,
    OurWorkingProcess,
    WhyChooseUs,
    BuildYourWebsite,
    CallUs,
    NumbersAreTalking,
    OurClients,
    LatestBlog,
    MainFooter,
  },
};
</script>