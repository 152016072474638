<template>
  <div class="coming-soon-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="coming-soon-content">
            <h1>Coming Soon</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices.
            </p>

            <div id="timer">
              <div id="days">
                {{ days }}
                <span>Days</span>
              </div>
              <div id="hours">
                {{ hours }}
                <span>Hours</span>
              </div>
              <div id="minutes">
                {{ minutes }}
                <span>Minutes</span>
              </div>
              <div id="seconds">
                {{ seconds }}
                <span>Seconds</span>
              </div>
            </div>

            <form class="newsletter-form" data-toggle="validator">
              <input
                type="email"
                class="input-newsletter"
                placeholder="Enter email address"
                name="EMAIL"
                required
                autocomplete="off"
              />

              <button type="submit" class="default-btn btn-bg-two">
                Notify Me
              </button>
              <div id="validator-newsletter" class="form-result"></div>
            </form>

            <ul class="header-content-right">
              <li>
                <a href="https://www.facebook.com/" target="_blank">
                  <i class="bx bxl-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/?lang=en" target="_blank">
                  <i class="bx bxl-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/" target="_blank">
                  <i class="bx bxl-linkedin-square"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <i class="bx bxl-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComingSoonPage",
  data() {
    return {
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
    };
  },
  created() {
    // Turn data into viewable values
    setInterval(() => {
      this.commingSoonTime();
    }, 1000);
  },
  methods: {
    commingSoonTime() {
      let endTime = new Date("December 5, 2025 17:00:00 PDT");
      let endTimeParse = Date.parse(endTime) / 1000;
      let now = new Date();
      let nowParse = Date.parse(now) / 1000;
      let timeLeft = endTimeParse - nowParse;
      let days = Math.floor(timeLeft / 86400);
      let hours = Math.floor((timeLeft - days * 86400) / 3600);
      let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
      let seconds = Math.floor(
        timeLeft - days * 86400 - hours * 3600 - minutes * 60
      );
      if (hours < "10") {
        hours = "0" + hours;
      }
      if (minutes < "10") {
        minutes = "0" + minutes;
      }
      if (seconds < "10") {
        seconds = "0" + seconds;
      }
      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    },
  },
};
</script>