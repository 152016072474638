<template>
  <div class="build-area-on pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="build-content build-content-on-color">
            <div class="section-title">
              <span>We Carry More Than Just Good Coding Skills</span>
              <h2>Let's Build Your Website!</h2>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="build-btn-area">
            <router-link
              to="/contact"
              class="default-btn btn-bg-two border-radius-50"
            >
              Contact Us
              <i class="bx bx-chevron-right"></i>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row pt-45">
        <div class="col-lg-4 col-md-6" v-for="item in items" :key="item.id">
          <div class="build-item">
            <router-link to="/case-study-details">
              <img :src="item.image" alt="Images" />
            </router-link>
            <div class="content">
              <h3>
                <router-link to="/case-study-details">
                  {{ item.title }}
                </router-link>
              </h3>
              <ul>
                <li v-for="tag in item.tags" :key="tag.id">
                  <router-link to="/case-study">
                    {{ tag.tag }}
                  </router-link>
                </li>
              </ul>
              <router-link to="/case-study-details" class="more-btn">
                <i class="bx bx-right-arrow-alt"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildYourWebsite",
  data() {
    return {
      items: [
        {
          id: 1,
          image: require("../../assets/images/build/build-img1.jpg"),
          title: "Web Development",
          tags: [
            {
              id: 1,
              tag: "Design",
            },
            {
              id: 2,
              tag: "Development",
            },
          ],
        },
        {
          id: 2,
          image: require("../../assets/images/build/build-img2.jpg"),
          title: "Social Media App",
          tags: [
            {
              id: 1,
              tag: "Design",
            },
            {
              id: 2,
              tag: "Social",
            },
          ],
        },
        {
          id: 3,
          image: require("../../assets/images/build/build-img3.jpg"),
          title: "UI/UX Research",
          tags: [
            {
              id: 1,
              tag: "UI/UX",
            },
            {
              id: 2,
              tag: "Design",
            },
          ],
        },
      ],
    };
  },
};
</script>