<template>
  <div class="banner-slider-area">
    <swiper
      :spaceBetween="30"
      :autoplay="{
        delay: 6000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }"
      :pagination="{
        clickable: true,
      }"
      :direction="vertical"
      :modules="modules"
      class="banner-slider"
    >
      <swiper-slide v-for="item in items" :key="item.id">
        <div
          class="banner-item"
          :style="{ 'background-image': `url(${item.bgImage})` }"
        >
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="banner-item-content">
                  <span>{{ item.subTitle }}</span>
                  <h1>{{ item.heading }}</h1>
                  <p>{{ item.desc }}</p>

                  <div class="banner-btn">
                    <router-link
                      to="/about"
                      class="default-btn btn-bg-two border-radius-50"
                    >
                      Learn More
                      <i class="bx bx-chevron-right"></i>
                    </router-link>

                    <router-link
                      to="/contact"
                      class="default-btn btn-bg-one border-radius-50 ml-20"
                    >
                      Get A Quote
                      <i class="bx bx-chevron-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper";

export default {
  name: "MainBanner",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          bgImage: require("../../assets/images/home-one/home-one-img1.jpg"),
          subTitle: "Only High Quality Services",
          heading: "Excellent IT Services for Your Success",
          desc: "Aenean Sollicitudin, Lorem quis Bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet",
        },
        {
          id: 2,
          bgImage: require("../../assets/images/home-one/home-one-img2.jpg"),
          subTitle: "Only High Quality Services",
          heading: "We Provide Best IT Services for Your Need",
          desc: "Aenean Sollicitudin, Lorem quis Bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet",
        },
        {
          id: 3,
          bgImage: require("../../assets/images/home-one/home-one-img3.jpg"),
          subTitle: "Only High Quality Services",
          heading: "Digital IT Service With Excellent Quality",
          desc: "Aenean Sollicitudin, Lorem quis Bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet",
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
};
</script>