<template>
  <div :class="['pt-100 pb-70', className]">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color1">Our Services</span>
        <h2>We Provide a Wide Variety of EdTech and IT Services</h2>
      </div>
      <div class="row pt-45 justify-content-center">
        <div
          class="col-lg-4 col-md-6"
          v-for="service in services"
          :key="service.id"
        >
          <div class="services-item">
            <!--<router-link to="/service-details">-->
              <router-link to="/">
              <img :src="service.image" alt="Images" />
            </router-link>
            <div class="content">
              <i class="flaticon-consultant"></i>
              <span>
                <!--<router-link to="/service-details">
                  {{ service.tag }}
                </router-link>-->
              </span>
              <h3>
                {{ service.title }}
                <!--<router-link to="/service-details">
                  {{ service.title }}
                </router-link>
                {{ service.hlink }}-->
              
                <!--<router-link :to="{ path: service.hlink }">
                  {{ service.title }}
                </router-link>-->
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
  props: ["className"],
  data() {
    return {
      services: [
        {
          id: 1,
          image: require("../../assets/images/services/services-img1.jpg"),
          icon: "flaticon-consultant",
          tag: "eLearning",
          title: "Custom Learning Development",
        },
        {
          id: 2,
          image: require("../../assets/images/services/services-img2.jpg"),
          icon: "flaticon-consulting",
          tag: "Animation",
          title: "2D and 3D Animation",
        },
        {
          id: 3,
          image: require("../../assets/images/services/services-img3.jpg"),
          icon: "flaticon-web-development",
          tag: "Web",
          title: "Web Development",
        },
        {
          id: 4,
          image: require("../../assets/images/services/services-img4.jpg"),
          icon: "flaticon-stats",
          tag: "K12",
          title: "Educational and K12 Development",
        },
        {
          id: 5,
          image: require("../../assets/images/services/services-img5.jpg"),
          icon: "flaticon-structure",
          tag: "Local",
          title: "Localization and Translation Solutions",
        },
        {
          id: 6,
          image: require("../../assets/images/services/services-img6.jpg"),
          icon: "flaticon-data-analytics",
          tag: "HTML",
          title: "Flash to HTML Conversion",
        },
      ],
    };
  },
};
</script>