<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/case-study/case-details.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>Careers</h2>
              <p>Come and be part of our team</p>
              <h5>
                If you are a customer-centric, collaborative, critical thinker with a passion for learning, who can help define EdTech future, you’ve come to the right place.
              </h5>
              <br />
              <p>
                What’s destination next? Is it better technology? A better you? Or a better community of makers? Wherever you desire to go, we give you the tools, the techniques, and the teams to navigate the journey. So you’re inspired to build what’s next, ensure your career never stands still, and navigate further together. Move forward. Take the world with you.
              </p>
              <p>
                At Lucreskill, our goal is to make you look forward to your workday – everyday! Sure, we have challenges. We have solutions to build, customer commitments to keep, and deadlines to meet. But, the journey will always be fun. The process will always be creative and however challenging the task, you will always have a team that stands by you.
              </p>
              <div class="row">
                <h2>Our Culture and Values</h2>
                <p>We’re committed to making our offices a best workplace for our employees. That means providing them with an environment that rewards forward-thinking: surrounding them with tools to not only get the job done but rethink how the job should be done. We want to listen, encourage, be transparent, and do right by one another. And we strive to do just that every day in every office.</p>
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Client Focus
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Innovation
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Accountability
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Best Workplace
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Motivation
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Respect
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Build Trust
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Diversity-Equality-Inclusion
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="case-article-content">
              <h2>Scale Your Organization’s Growth With Custom Software Development Solutions</h2>
                <p>Passion, Perseverance, Perfection – we are defined by these three words. Relentless in our pursuit of knowledge, we believe in accepting the difference and defining the 'new normal', staying true to our vision and values. We believe in growth by knowledge, responsibility by authority and freedom by flexibility. Our doors are wide open, and breakthrough ideas are welcome from anyone.</p>
                <p>Connecting young talent means incorporating new ways of seeing things, ensuring that we have agile, restless and even rebellious disruptors, who bring innovation and creativity to the way we work and generate value. At Lucreskill we know this and we are committed to young talent as one of our strategic priorities. </p>
                <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Instructional Designer (IDE)
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Content Writers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Subject Matter Experts
                    </li> 
                    <li>
                      <i class="bx bxs-check-circle"></i>Copy Editor
                    </li>             
                    <li>
                      <i class="bx bxs-check-circle"></i>Project Manager
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Project Coordinator
                    </li> 
                    <li>
                      <i class="bx bxs-check-circle"></i>Business Development Executives
                    </li> 
                    <li>
                      <i class="bx bxs-check-circle"></i>Human Resource
                    </li> 
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Graphics Designers
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>After Effect – Video Editing.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Articulate Storyline Developer
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Flash ( Character Animation and Character Illustration) 2D/3D
                    </li> 
                    <li>
                      <i class="bx bxs-check-circle"></i>Web Developer
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Team Lead
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Quality Analysts
                    </li>              
                  </ul>
                </div>
              </div>
            </div>
            <div class="case-article-content">
              <h2>Current Openings</h2>
              <p>Are you ready to join us, learn and discover new areas of data solutions, EdTech, education, research content? Are you ready to innovate and build technology that delivers above and beyond our customer expectations? Are you ready to collaborate in a diverse and inclusive environment that adds value for our clients, our teams, and communities around us? Are you ready to celebrate Lucreskill?</p>
                <p>Please send your updated CV with specific role you are looking for at <u>hr@lucreskilltech.com</u></p>
                <p>                
                Thanks for checking out our job openings. Apply below if you see something that interests you.</p>
              </div>
            <div class="case-play-btn">
              <a
                class="case-play"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>

            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucerskill is often at the forefront of adopting new learning technologies and methodologies, 
                ensuring that your organization benefits from the latest advancements by assuring commitment
                to keep content current and providing ongoing technical and customer support. We are adaptive 
                in learning techniques to tailor content to individual learner needs, providing a more 
                personalized experience with our cost effective solution compared to traditional in-person training or education.
                Elearning can lead to increased job satisfaction and learner satisfaction, as 
                it empowers individuals to learn at their own pace and in their preferred style.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <p>
              </p>
            </div>            
            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through 
                creativity, originality, and customization to meet the 
                needs of specific audience. By focusing on these aspects 
                at each stage of development, we are creating eLearning 
                materials that stand out and effectively engage learners.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "AnimationVideoDev",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>