<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/programming-background-collage.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>FLASH TO HTML5 CONVERSION</h2>
              <p>                
                Converting your old Flash-based courses to HTML5 mobile responsive elearning courses.
              </p>
              <p>                
                When an organization want to convert their Flash based courses to HTML5, the major constrain will be the budget. Migration is an overhead cost to the organization, especially if the courses are in high number. Considering the budget and the timeline, here are different approaches we follow for converting from Flash to HTML5.
              </p>

              <p>Our Flash to HTML5 conversion services includes:</p>
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Mobile friendliness
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Less Power consumption
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Better SEO strategies
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Security concerns
                    </li>
                  </ul>
                </div>
              </div>
            </div>                   
            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucreskill is often at the forefront of adopting new learning technologies and methodologies, 
                ensuring that your organization benefits from the latest advancements by assuring commitment 
                to keep content current and providing ongoing technical and customer support. We are adaptive 
                in learning techniques to tailor content to individual learner needs, providing a more 
                personalized experience with our cost effective solution compared to traditional in-person 
                training or education. Elearning can lead to increased job satisfaction and learner 
                satisfaction, as it empowers individuals to learn at their own pace and in their preferred 
                style.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <p>
                eLearning offers numerous benefits for education and trainings in all aspects. 
                These advantages can enhance the learning experience for students, 
                teachers, and schools along with access to a vast array of educational resources, 
                including online textbooks, interactive simulations, virtual labs, and multimedia 
                content, enriching the learning experience.
              </p>
              </div>
            </div>  
            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through creativity, originality, and customization to meet the needs of specific audience. By focusing on these aspects at each stage of development, we are creating eLearning materials that stand out and effectively engage learners.
              </p>
            </div>       
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "AnimationVideoDev",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>