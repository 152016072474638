<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/industrial-fire.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>Fire Safety</h2>
              <p>                
                Every year, the fire service attends hundreds of thousands of fires, a significant portion of which occur in the workplace. The threat to safety is obvious, and both employers and staff have legal responsibilities to ensure that procedures are followed and all due care is taken.
                This course, set in a Environment for offices, schools, industrial, residential societies etc. It is designed to provide a detailed overview of good fire safety practice. We look at identifying potential risks, what to do in the event of a blaze and how to utilise different types of fire-fighting equipment.
              </p>

              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Realize the methodologies involved in fire formation.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Familiarize with workplace Inspection & reporting procedures.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Outline the factors involved in the prevention of fire.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Recognize the different types of fire extinguisher & their usage.
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Discuss the classification & causes of fire at the workplace.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Discuss the importance of various fire control mechanisms.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Describes various fire safety devices & their working mechanisms.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Details the essential requirements of the emergency evacuation plan.
                    </li>
                  </ul>
                </div>
              </div>

              <p>
                The objective of the training is to provide proficiency in accessing the workplace hazards and effectively handle the fire control / preventive mechanism. Fire safety training offers expertise in theoretical knowledge & hands-on experience in handling various firefighting equipment and helps to understand the organizational level of preparedness in dealing with emergencies.
              </p>
              <h2>Product Demo</h2>
              <p>
                For Product Case Study, Live Demo and Purchase please visit :           
              <a href="https://product.lucreskilltech.com/assests/Demo/FS/story_html5.html" target="_blank">Fire Safety Awareness Programme.</a>
              </p>
              <h4>For Purchase</h4>  
              <p>                          
                <!--<a href="https://product.lucreskilltech.com/" target="_blank">Sexual Harrashment.</a>-->
                Please contact Support at :  <ul>
                                                <li>
                                                  <b>+91 77091 38516</b>
                                                </li>
                                                <li>
                                                  <b>+1 (214) 431-5175</b>
                                                </li>
                                              </ul>                
              </p>
            </div>

            <div class="case-play-btn">
              <a
                class="case-play"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>

            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucreskill Tech provide wide range of benefits to various stakeholders, including learners, organizations, and instructors.
                We often costs less than traditional in-person education. There are no commuting expenses, and many elearning courses are more affordable than their in-person equivalents.
                Elearning platforms often provide tools for tracking learners' progress and performance. Instructors and organizations can use this data to evaluate and improve educational outcomes.
                Professionals can use elearning to stay up-to-date with the latest industry developments, certifications, and best practices.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <p>
                eLearning offers numerous benefits for education and trainings in all aspects. 
                These advantages can enhance the learning experience for students, 
                teachers, and schools along with access to a vast array of educational resources, 
                including online textbooks, interactive simulations, virtual labs, and multimedia 
                content, enriching the learning experience.
              </p>
            </div>

            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through 
                creativity, originality, and customization to meet the 
                needs of specific audience. By focusing on these aspects 
                at each stage of development, we are creating eLearning 
                materials that stand out and effectively engage learners.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "FireSafetyDetails",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>