<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/dual-video.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>Video & Audio Production</h2>
              <p>                
                Nothing explains better than an audio-visual rendition. And, with growth of digital marketing, video content has grown to be the most powerful tool to reach out to your current and potential audience. Our team of video producers and editors use their unique storytelling skills to create animated logos, motion graphics, on-screen characters, animated short films, and infographics. We help you create videos for marketers, educators, corporates, and startups, which reflect your organization’s persona and gets across your message, instructions, or information in a comprehensive, yet interesting manner.
              </p>

              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Realize the methodologies involved in fire formation.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Familiarize with workplace Inspection & reporting procedures.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Outline the factors involved in the prevention of fire.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Recognize the different types of fire extinguisher & their usage.
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Discuss the classification & causes of fire at the workplace.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Discuss the importance of various fire control mechanisms.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Describes various fire safety devices & their working mechanisms.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Details the essential requirements of the emergency evacuation plan.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="case-article-content">
              <h2>Audio Video Localization Services</h2>
              <p>                
                Lucreskill provides creative, fast, and accurate video editing services. We deliver multimedia in finished form, all complete with quality, and accuracy. Our expert linguists and multimedia teams, can transform your video into engaging multilingual content with a global reach, weeding out any glitches or issues in your videos.</p>
                <div class="row">
                <div class="col-lg-12 col-md-12">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Color Grading
                      <p>It  is more about the  colour and visual style of the image or video. It combines technical and creative skills and is important in achieving both tone and clarity in a film</p>
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Audio / Video Editing
                      <p>The audio editing process generally involves: editing the length of audio files. adjusting the volume. making sure the different sound elements are balanced to suit your desired end result. Video editing includes cutting segments (trimming), re-sequencing clips, and adding transitions and other special effects.</p>
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Motion Graphics
                      <p>An animation or digital footage which create the illusion of motion or rotation, and are usually combined with audio for use in multimedia projects and our LST team is expertise in creating such linguistic work.</p>
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Explainer Video
                      <p>It is a short video that communicates how something works in a simple way to its target audience. Explainer videos are often used in marketing to show the benefits of a product or service and how they work.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/Animation-1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/Animation-2.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <br />
            <div class="case-article-content">
              <h2>Illustrations & Infographics</h2>
              <p>                
                Illustrations add an artistic touch to plain content. We offer customized, hand-drawn and digital illustration as a standalone service or to support various digital applications, digital broadcasting, and print media. Using the latest software tools and techniques, we create head-turning illustrations and lucid infographics that elevate the aesthetic quotient of your business content, and include.</p>
                <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Digital Painting Services
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Animation Character Design
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Animation Character Development
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Character Design Animation
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Character Development animation
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Cartoon Character Design
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Cartoon Character Development
                    </li>                    
                  </ul>
                </div>
              </div>
            </div>
            <div class="case-article-content">
              <h2>2D / 3D Animation</h2>
              <p>                
                Add an element of fun to your content with animated videos or characters. We have a team of creative storytellers who can bring life to your business persona using distinctive and meaningful animation. Our talented directors, filmmakers, scriptwriters, illustrators, animators, photographers, and designers come together to provide an end-to-end animation solution from brainstorming to final release. We combine high-end, innovative technologies and original ideas to create 2D and 3D videos for animated infographics, whiteboard animation, 2D/3D motion graphics, product visualization, CAD import and visual effects, with utmost perfection..</p>
            </div>
            <div class="case-play-btn">
              <a
                class="case-play"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>

            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucreskill is often at the forefront of adopting new learning technologies and methodologies, 
                ensuring that your organization benefits from the latest advancements by assuring commitment 
                to keep content current and providing ongoing technical and customer support. We are adaptive 
                in learning techniques to tailor content to individual learner needs, providing a more 
                personalized experience with our cost effective solution compared to traditional in-person 
                training or education. Elearning can lead to increased job satisfaction and learner 
                satisfaction, as it empowers individuals to learn at their own pace and in their preferred 
                style.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <p>
                eLearning offers numerous benefits for education and trainings in all aspects. 
                These advantages can enhance the learning experience for students, 
                teachers, and schools along with access to a vast array of educational resources, 
                including online textbooks, interactive simulations, virtual labs, and multimedia 
                content, enriching the learning experience.
              </p>
            </div>

            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through 
                creativity, originality, and customization to meet the 
                needs of specific audience. By focusing on these aspects 
                at each stage of development, we are creating eLearning 
                materials that stand out and effectively engage learners.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "AnimationVideoDev",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>