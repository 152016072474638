<template>
  <div class="terms-conditions-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <h2>Lucreskill Tech Terms & Conditions</h2>
      </div>
      <div class="row pt-45">
        <div class="col-lg-12">
          <div class="terms-conditions-img">
            <img
              src="../../assets/images/terms-condition-img.jpg"
              alt="Images"
            />
          </div>

          <div class="single-content">
            <h3>Legal Disclaimer</h3>
            <p>
              Maecenas non sem ex. Nullam ac augue vel turpis fringilla maximus.
              Vivamus commodo laoreet augue non porta. Nam egestas dui turpis,
              non pulvinar nisi rhoncus eu. Phasellus et sollicitudin nulla, at
              efficitur nunc. Quisque et neque commodo, blandit lacus malesuada,
              scelerisque ante. Suspendisse lacinia tristique justo, vitae
              ornare ipsum interdum sed. Vestibulum porttitor urna eget nunc
              porttitor rutrum. Aliquam tempor sapien orci, quis mollis velit
              laoreet sit amet. Morbi luctus magna tellus, quis accumsan nisi
              cursus id. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Sed varius convallis massa, sed ultrices dolor fermentum id.
              Nunc vel ex sed sapien hendrerit consequat pharetra a metus.
            </p>
            <p>
              Vestibulum eu aliquet justo. Aenean at mauris leo. Etiam placerat
              ac turpis ac feugiat. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Fusce
              velit nibh, consequat sed mi in, consectetur posuere erat. Ut
              mauris arcu, finibus quis lectus et, hendrerit rutrum erat.
              Integer varius pharetra arcu, et rhoncus leo commodo sed. Nullam
              sollicitudin pulvinar pharetra. In ut lacinia sem.
            </p>
          </div>

          <div class="single-content">
            <h3>01. Credit Reporting Terms of Service</h3>
            <p>
              Maecenas non sem ex. Nullam ac augue vel turpis fringilla maximus.
              Vivamus commodo laoreet augue non porta. Nam egestas dui turpis,
              non pulvinar nisi rhoncus eu. Phasellus et sollicitudin nulla, at
              efficitur nunc. Quisque et neque commodo, blandit lacus malesuada,
              scelerisque ante. Suspendisse lacinia tristique justo, vitae
              ornare ipsum interdum sed. Vestibulum porttitor urna eget nunc
              porttitor rutrum. Aliquam tempor sapien orci, quis mollis velit
              laoreet sit amet. Morbi luctus magna tellus, quis accumsan nisi
              cursus id. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Sed varius convallis massa, sed ultrices dolor fermentum id.
              Nunc vel ex sed sapien hendrerit consequat pharetra a metus.
            </p>
            <p>
              Vestibulum eu aliquet justo. Aenean at mauris leo. Etiam placerat
              ac turpis ac feugiat. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Fusce
              velit nibh, consequat sed mi in, consectetur posuere erat. Ut
              mauris arcu, finibus quis lectus et, hendrerit rutrum erat.
              Integer varius pharetra arcu, et rhoncus leo commodo sed. Nullam
              sollicitudin pulvinar pharetra. In ut lacinia sem.
            </p>
          </div>

          <div class="single-content">
            <h3>02. Ownership of Site Agreement to Terms of Use</h3>
            <p>
              Maecenas non sem ex. Nullam ac augue vel turpis fringilla maximus.
              Vivamus commodo laoreet augue non porta. Nam egestas dui turpis,
              non pulvinar nisi rhoncus eu. Phasellus et sollicitudin nulla, at
              efficitur nunc. Quisque et neque commodo, blandit lacus malesuada,
              scelerisque ante. Suspendisse lacinia tristique justo, vitae
              ornare ipsum interdum sed. Vestibulum porttitor urna eget nunc
              porttitor rutrum. Aliquam tempor sapien orci, quis mollis velit
              laoreet sit amet. Morbi luctus magna tellus, quis accumsan nisi
              cursus id. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Sed varius convallis massa, sed ultrices dolor fermentum id.
              Nunc vel ex sed sapien hendrerit consequat pharetra a metus.
            </p>
            <p>
              Vestibulum eu aliquet justo. Aenean at mauris leo. Etiam placerat
              ac turpis ac feugiat. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Fusce
              velit nibh, consequat sed mi in, consectetur posuere erat. Ut
              mauris arcu, finibus quis lectus et, hendrerit rutrum erat.
              Integer varius pharetra arcu, et rhoncus leo commodo sed. Nullam
              sollicitudin pulvinar pharetra. In ut lacinia sem.
            </p>
          </div>

          <div class="single-content">
            <h3>03. Provision of Services</h3>
            <p>
              Maecenas non sem ex. Nullam ac augue vel turpis fringilla maximus.
              Vivamus commodo laoreet augue non porta. Nam egestas dui turpis,
              non pulvinar nisi rhoncus eu. Phasellus et sollicitudin nulla, at
              efficitur nunc. Quisque et neque commodo, blandit lacus malesuada,
              scelerisque ante. Suspendisse lacinia tristique justo, vitae
              ornare ipsum interdum sed. Vestibulum porttitor urna eget nunc
              porttitor rutrum. Aliquam tempor sapien orci, quis mollis velit
              laoreet sit amet. Morbi luctus magna tellus, quis accumsan nisi
              cursus id. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Sed varius convallis massa, sed ultrices dolor fermentum id.
              Nunc vel ex sed sapien hendrerit consequat pharetra a metus.
            </p>
            <p>
              Vestibulum eu aliquet justo. Aenean at mauris leo. Etiam placerat
              ac turpis ac feugiat. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Fusce
              velit nibh, consequat sed mi in, consectetur posuere erat. Ut
              mauris arcu, finibus quis lectus et, hendrerit rutrum erat.
              Integer varius pharetra arcu, et rhoncus leo commodo sed. Nullam
              sollicitudin pulvinar pharetra. In ut lacinia sem.
            </p>
          </div>

          <div class="single-content">
            <h3>04. Limitation of Liability</h3>
            <p>
              Maecenas non sem ex. Nullam ac augue vel turpis fringilla maximus.
              Vivamus commodo laoreet augue non porta. Nam egestas dui turpis,
              non pulvinar nisi rhoncus eu. Phasellus et sollicitudin nulla, at
              efficitur nunc. Quisque et neque commodo, blandit lacus malesuada,
              scelerisque ante. Suspendisse lacinia tristique justo, vitae
              ornare ipsum interdum sed. Vestibulum porttitor urna eget nunc
              porttitor rutrum. Aliquam tempor sapien orci, quis mollis velit
              laoreet sit amet. Morbi luctus magna tellus, quis accumsan nisi
              cursus id. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Sed varius convallis massa, sed ultrices dolor fermentum id.
              Nunc vel ex sed sapien hendrerit consequat pharetra a metus.
            </p>
            <p>
              Vestibulum eu aliquet justo. Aenean at mauris leo. Etiam placerat
              ac turpis ac feugiat. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Fusce
              velit nibh, consequat sed mi in, consectetur posuere erat. Ut
              mauris arcu, finibus quis lectus et, hendrerit rutrum erat.
              Integer varius pharetra arcu, et rhoncus leo commodo sed. Nullam
              sollicitudin pulvinar pharetra. In ut lacinia sem.
            </p>
          </div>

          <div class="single-content">
            <h3>05. Accounts, Passwords and Security</h3>
            <p>
              Maecenas non sem ex. Nullam ac augue vel turpis fringilla maximus.
              Vivamus commodo laoreet augue non porta. Nam egestas dui turpis,
              non pulvinar nisi rhoncus eu. Phasellus et sollicitudin nulla, at
              efficitur nunc. Quisque et neque commodo, blandit lacus malesuada,
              scelerisque ante. Suspendisse lacinia tristique justo, vitae
              ornare ipsum interdum sed. Vestibulum porttitor urna eget nunc
              porttitor rutrum. Aliquam tempor sapien orci, quis mollis velit
              laoreet sit amet. Morbi luctus magna tellus, quis accumsan nisi
              cursus id. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Sed varius convallis massa, sed ultrices dolor fermentum id.
              Nunc vel ex sed sapien hendrerit consequat pharetra a metus.
            </p>
            <p>
              Vestibulum eu aliquet justo. Aenean at mauris leo. Etiam placerat
              ac turpis ac feugiat. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Fusce
              velit nibh, consequat sed mi in, consectetur posuere erat. Ut
              mauris arcu, finibus quis lectus et, hendrerit rutrum erat.
              Integer varius pharetra arcu, et rhoncus leo commodo sed. Nullam
              sollicitudin pulvinar pharetra. In ut lacinia sem.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
};
</script>