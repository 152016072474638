<template>
  <div class="about-area ptb-100">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6">
          <div class="about-play">
            <img
              src="../../assets/images/about/about-img1.jpg"
              alt="About Images"
            />
            <div class="about-play-content">
              <span>Watch Our Intro Video</span>
              <h2>Perfect Solution for EdTech Services!</h2>
              <div class="play-on-area">
                <div
                  class="play-on"
                  style="cursor: pointer"
                  v-on:click="isPopupMethod(isPopup)"
                >
                  <i class="bx bx-play"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="about-content ml-25">
            <div class="section-title">
              <span class="sp-color2">12 Years of Experience</span>
              <h2>Right Partner for EdTech and Software Innovation</h2>
              <p>
                For over 12 years, Lucreskill Tech has been a best-in-class elearning content provider software company.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <ul class="about-list text-start">
                  <li>
                    <i class="bx bxs-check-circle"></i>Every Stage Learning Solutions
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Helping you keep your promises
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Cost of EdTech Content Development
                  </li>
                </ul>
              </div>

              <div class="col-lg-6 col-md-6">
                <ul class="about-list text-start about-list-2">
                  <li>
                    <i class="bx bxs-check-circle"></i>Good Quality EdTech Services
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Easy to Customer Services
                  </li>
                  <li>
                    <i class="bx bxs-check-circle"></i>Global collaboration and cultural understanding.
                  </li>
                </ul>
              </div>
            </div>
            <p class="about-content-text">
              A unique learning experience through technology, content and effective strategies. We transform learning strategies into real business success. Thus enhance the experience for millions of learners at every stage of life, from the earliest days of school to the working world.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SoftwareInnovation",
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>