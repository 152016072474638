<template>
  <div :class="['ptb-100', className]">
    <div class="container">
      <swiper
        :slidesPerView="'auto'"
        :grabCursor="true"
        :freeMode="true"
        :spaceBetween="60"
        :speed="11000"
        :a11y="false"
        :loop="true"
        :autoplay="{
          delay: 0.5,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          0: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 5,
          },
        }"
        :modules="modules"
        class="brand-slider"
      >
        <swiper-slide
          class="text-center"
          v-for="brand in brandItem"
          :key="brand.id"
        >
          <div class="brand-item">
            <img :src="brand.logoOne" class="brand-logo-one" alt="Images" />
            <img :src="brand.logoTwo" class="brand-logo-two" alt="Images" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";

export default {
  name: "BrandLogo",
  props: ["className"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      brandItem: [
        {
          id: 1,
          logoOne: require("../../assets/images/brand-logo/Aptara.png"),
          logoTwo: require("../../assets/images/brand-logo/Aptara.png"),
        },
        {
          id: 2,
          logoOne: require("../../assets/images/brand-logo/AK_technologies.png"),
          logoTwo: require("../../assets/images/brand-logo/AK_technologies.png"),
        },
        {
          id: 3,
          logoOne: require("../../assets/images/brand-logo/GC_Solutions.png"),
          logoTwo: require("../../assets/images/brand-logo/GC_Solutions.png"),
        },
        {
          id: 4,
          logoOne: require("../../assets/images/brand-logo/Hurix.png"),
          logoTwo: require("../../assets/images/brand-logo/Hurix.png"),
        },
        {
          id: 5,
          logoOne: require("../../assets/images/brand-logo/Integra.png"),
          logoTwo: require("../../assets/images/brand-logo/Integra.png"),
        },
        {
          id: 6,
          logoOne: require("../../assets/images/brand-logo/LearningMate.png"),
          logoTwo: require("../../assets/images/brand-logo/LearningMate.png"),
        },
        {
          id: 7,
          logoOne: require("../../assets/images/brand-logo/Magic_Software.png"),
          logoTwo: require("../../assets/images/brand-logo/Magic_Software.png"),
        },
        {
          id: 8,
          logoOne: require("../../assets/images/brand-logo/NIIT.png"),
          logoTwo: require("../../assets/images/brand-logo/NIIT.png"),
        },
        {
          id: 9,
          logoOne: require("../../assets/images/brand-logo/Six_Red_Marble.png"),
          logoTwo: require("../../assets/images/brand-logo/Six_Red_Marble.png"),
        },
        {
          id: 10,
          logoOne: require("../../assets/images/brand-logo/Straive.png"),
          logoTwo: require("../../assets/images/brand-logo/Straive.png"),
        },
        {
          id: 11,
          logoOne: require("../../assets/images/brand-logo/XSEED.png"),
          logoTwo: require("../../assets/images/brand-logo/XSEED.png"),
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>