<template>
  <div class="services-widget-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Services</span>
        <h2>We Provide a Wide Variety of EdTech and IT Services</h2>
      </div>
      <div class="row pt-45 justify-content-center">
        <div
          class="col-lg-4 col-md-6"
          v-for="service in services"
          :key="service.id"
        >
          <div class="services-item">
            <router-link to="/service-details">
              <img :src="service.image" alt="Images" />
            </router-link>
            <div class="content">
              <i class="flaticon-consultant"></i>
              <span>
                <router-link to="/service-details">
                  {{ service.tag }}
                </router-link>
              </span>
              <h3>
                <router-link to="/service-details">
                  {{ service.title }}
                </router-link>
              </h3>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 text-center">
          <div class="pagination-area">
            <a href="/services" class="prev page-numbers">
              <i class="bx bx-left-arrow-alt"></i>
            </a>

            <span class="page-numbers current" aria-current="page">1</span>
            <a href="/services" class="page-numbers">2</a>
            <a href="/services" class="page-numbers">3</a>

            <a href="/services" class="next page-numbers">
              <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesContent",
  data() {
    return {
      services: [
        {
          id: 1,
          image: require("../../assets/images/services/services-img1.jpg"),
          icon: "flaticon-consultant",
          tag: "IT",
          title: "IT Consulting",
        },
        {
          id: 2,
          image: require("../../assets/images/services/services-img2.jpg"),
          icon: "flaticon-consulting",
          tag: "Cloud",
          title: "Cloud Computing",
        },
        {
          id: 3,
          image: require("../../assets/images/services/services-img3.jpg"),
          icon: "flaticon-web-development",
          tag: "Web",
          title: "Web Development",
        },
        {
          id: 4,
          image: require("../../assets/images/services/services-img4.jpg"),
          icon: "flaticon-stats",
          tag: "Business",
          title: "Business Reform",
        },
        {
          id: 5,
          image: require("../../assets/images/services/services-img5.jpg"),
          icon: "flaticon-structure",
          tag: "Planing",
          title: "Infrastructure",
        },
        {
          id: 6,
          image: require("../../assets/images/services/services-img6.jpg"),
          icon: "flaticon-data-analytics",
          tag: "Analysis",
          title: "Data Analysis",
        },
      ],
    };
  },
};
</script>