<template>
  <div class="case-study-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Case Study</span>
        <h2>Introduce Our Projects and Check Recent Work</h2>
      </div>

      <div class="row pt-45 justify-content-center">
        <div class="col-lg-4 col-md-6" v-for="item in items" :key="item.id">
          <div class="case-study-item">
            <router-link to="/case-study-details">
              <img :src="item.image" alt="Images" />
            </router-link>
            <div class="content">
              <h3>
                {{ item.title }}
                <!--<router-link to="/case-study-details">
                  {{ item.title }}
                </router-link>-->
              </h3>
              <ul>
                <li v-for="tag in item.tags" :key="tag.id">
                  {{ tag.tag }}
                  <!-- <router-link to="/case-study">{{ tag.tag }}</router-link>-->
                </li>
              </ul>
              <router-link to="/case-study-details" class="more-btn">
                <i class="bx bx-right-arrow-alt"></i>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 text-center">
          <div class="pagination-area">
            <a href="/case-study" class="prev page-numbers">
              <i class="bx bx-left-arrow-alt"></i>
            </a>
            <span class="page-numbers current" aria-current="page">1</span>
            <a href="/case-study" class="page-numbers">2</a>
            <a href="case-study" class="page-numbers">3</a>
            <a href="/case-study" class="next page-numbers">
              <i class="bx bx-right-arrow-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStudy",
  data() {
    return {
      items: [
        {
          id: 1,
          image: require("../../assets/images/case-study/case-study1.jpg"),
          title: "Custom Learning Development",
          tags: [
            {
              id: 1,
              tag: "Business",
            },
            {
              id: 2,
              tag: "Planing",
            },
          ],
        },
        {
          id: 2,
          image: require("../../assets/images/case-study/case-study2.jpg"),
          title: "2D and 3D Animation",
          tags: [
            {
              id: 1,
              tag: "Digital",
            },
            {
              id: 2,
              tag: "Marketing",
            },
          ],
        },
        {
          id: 3,
          image: require("../../assets/images/case-study/case-study3.jpg"),
          title: "Web Application",
          tags: [
            {
              id: 1,
              tag: "Web Development",
            },
            {
              id: 2,
              tag: "Planing",
            },
          ],
        },
        {
          id: 4,
          image: require("../../assets/images/case-study/case-study4.jpg"),
          title: "Educational and K12 Development",
          tags: [
            {
              id: 1,
              tag: "Design",
            },
            {
              id: 2,
              tag: "Development",
            },
          ],
        },
        {
          id: 5,
          image: require("../../assets/images/case-study/case-study5.jpg"),
          title: "Localization and Translation Solutions",
          tags: [
            {
              id: 1,
              tag: "Business",
            },
            {
              id: 2,
              tag: "Analysis",
            },
          ],
        },
        {
          id: 6,
          image: require("../../assets/images/case-study/case-study6.jpg"),
          title: "Flash to HTML Conversion",
          tags: [
            {
              id: 1,
              tag: "Media",
            },
            {
              id: 2,
              tag: "Marketing",
            },
          ],
        },
      ],
    };
  },
};
</script>