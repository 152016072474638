<template>
  <div class="about-area about-bg pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="about-img-2">
            <img
              src="../../assets/images/about/about-img3.jpg"
              alt="About Images"
            />
          </div>
        </div>

        <div class="col-lg-6">
          <div class="about-content-2 ml-20">
            <div class="section-title">
              <span class="sp-color1">About Us</span>
              <h2>Right Partner for Software Innovation</h2>
              <p>
                Aenean sollicitudin, lorem quis bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio
                sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan
                ipsum auctor a ornare odio.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-6">
                <div class="about-card">
                  <div class="content">
                    <i class="flaticon-practice"></i>
                    <h3>Experience</h3>
                  </div>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet
                  </p>
                </div>
              </div>

              <div class="col-lg-6 col-6">
                <div class="about-card">
                  <div class="content">
                    <i class="flaticon-help"></i>
                    <h3>Quick Support</h3>
                  </div>
                  <p>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit
                    amet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>