<template>
  <div class="build-area pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-8">
          <div class="build-content">
            <div class="section-title">
              <span>We Carry More Than Just Good Coding Skills</span>
              <h2>Let's Build Your Website!</h2>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="build-btn-area">
            <router-link
              to="/contact"
              class="default-btn btn-bg-two border-radius-50"
            >
              Contact Us
              <i class="bx bx-chevron-right"></i>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row pt-45">
        <div class="col-lg-12">
          <div class="play-btn-area">
            <div
              class="build-play"
              style="cursor: pointer"
              v-on:click="isPopupMethod(isPopup)"
            >
              <i class="bx bx-play"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildYourWebsite",
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>