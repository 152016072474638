<template>
  <div class="choose-area-tow pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="choose-content-two">
            <div class="section-title">
              <span class="sp-color2">Why Choose Us</span>
              <h2>12 Years of Experience for EdTech Solutions and Innovation</h2>
              <p>
                Our customers are at the heart of everything we do and our customer centric approach
                prioritize your needs and preferences, working closely with you to deliver solutions that align with your goals.
              </p>
            </div>
            <div class="row">
              <div class="col-lg-12 col-sm-6">
                <div class="choose-content-card">
                  <i class="flaticon-practice"></i>
                  <h3>Experience Employment</h3>
                  <p>
                    We have a proven track record of success in our industry. Our team brings years of experience and expertise to 
                    the table, ensuring that you receive the highest quality eLearning services and products.
                  </p>
                </div>
              </div>

              <div class="col-lg-12 col-sm-6">
                <div class="choose-content-card">
                  <i class="flaticon-help"></i>
                  <h3>Quick Support Replay</h3>
                  <p>
                    Our dedicated customer support technical team is always ready to assist you. 
                    We believe in clear communication and prompt responses to any questions or concerns you may have.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="choose-img-two">
            <img
              src="../../assets/images/choose-img/choose-img1.jpg"
              alt="About Images"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUs",
};
</script>