<template>
  <div class="banner-area-two">
    <div class="container-fluid">
      <div class="container-max">
        <div class="row align-items-center">
          <div class="col-lg-5">
            <div class="banner-content">
              <h1>Digital EdTech Services With Excellent Quality</h1>
              <p>
                Aenean Sollicitudin, Lorem quis Bibendum auctor, nisi elit
                consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio
                sit amet nibh vulputate cursus a sit amet
              </p>
              <div class="banner-btn">
                <router-link
                  to="/about"
                  class="default-btn btn-bg-two border-radius-50"
                >
                  Learn More
                  <i class="bx bx-chevron-right"></i>
                </router-link>
                <router-link
                  to="/contact"
                  class="default-btn btn-bg-one border-radius-50 ml-20"
                >
                  Get A Quote
                  <i class="bx bx-chevron-right"></i>
                </router-link>
              </div>
            </div>
          </div>

          <div class="col-lg-7">
            <div class="banner-img">
              <img
                src="../../assets/images/home-three/home-three-img.png"
                alt="Images"
              />
              <div class="banner-img-shape">
                <img
                  src="../../assets/images/home-three/home-three-shape.png"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <BannerSubItem />
    </div>
  </div>
</template>

<script>
import BannerSubItem from "./BannerSubItem";

export default {
  name: "MainBanner",
  components: {
    BannerSubItem,
  },
};
</script>