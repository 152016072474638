<template>
  <div class="clients-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Clients</span>
        <h2>Our Clients Feedback</h2>
      </div>

      <swiper
        :spaceBetween="30"
        :grabCursor="true"
        :autoplay="{
          delay: 4000,
          disableOnInteraction: true,
        }"
        :pagination="{
          clickable: true,
        }"
        :breakpoints="{
          0: {
            slidesPerView: 1,
          },
          992: {
            slidesPerView: 2,
          },
        }"
        :modules="modules"
        class="clients-slider pt-45"
      >
        <swiper-slide v-for="client in clients" :key="client.id">
          <div class="clients-content">
            <div class="content">
              <img :src="client.image" alt="Images" />
              <i class="bx bxs-quote-alt-left"></i>
              <h3>{{ client.name }}</h3>
              <span>{{ client.designation }}</span>
            </div>
            <p>{{ client.desc }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="client-circle">
      <div class="client-circle-1">
        <div class="circle"></div>
      </div>
      <div class="client-circle-2">
        <div class="circle"></div>
      </div>
      <div class="client-circle-3">
        <div class="circle"></div>
      </div>
      <div class="client-circle-4">
        <div class="circle"></div>
      </div>
      <div class="client-circle-5">
        <div class="circle"></div>
      </div>
      <div class="client-circle-6">
        <div class="circle"></div>
      </div>
      <!-- <div class="client-circle-7">
        <div class="circle"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper";

export default {
  name: "OurClients",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      clients: [
        {
          id: 1,
          image: require("../../assets/images/clients-img/clients-img1.jpg"),
          desc: "“Great vendor, very responsive to feedback and excellence EdTech services provided so far with customer service. Will be in our preferred vendor list”",
          name: "Anil Parasher",
          designation: "CEO and Founder",
        },
        {
          id: 2,
          image: require("../../assets/images/clients-img/clients-img2.jpg"),
          desc: "“We are working with Lucreskill Tech for several years and we highly recommend them for the quality work.”",
          name: "Abhinav Srivastava",
          designation: "Vendor Engagement",
        },
        {
          id: 3,
          image: require("../../assets/images/clients-img/clients-img1.jpg"),
          desc: "“We found Lucreskill Tech to be the complete solution. It provided an extremely wide array of functionality”",
          name: "Dr Rajeev",
          designation: "Programme Director",
        },
        {
          id: 4,
          image: require("../../assets/images/clients-img/clients-img2.jpg"),
          desc: "“Lucreskill Tech offered everything we needed in terms of enterprise functionality and customization options.”",
          name: "Kamal Arora",
          designation: "CTO",
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
};
</script>