<template>
  <header class="top-header top-header-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-6">
          <div class="top-head-left">
            <div class="top-contact">
              <h3>
                Support By :
                <a href="tel:+1(212)-255-5511">+1 (212) 255-5511</a>
              </h3>
            </div>
          </div>
        </div>

        <div class="col-lg-5 col-md-6">
          <div class="top-header-right">
            <div class="top-header-social top-header-social-bg">
              <ul>
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/?lang=en" target="_blank">
                    <i class="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank">
                    <i class="bx bxl-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TopHeaderTwo",
};
</script>