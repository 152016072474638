<template>
  <div :class="['navbar-area', { sticky: isSticky }]" id="navbar">
    <div class="main-nav">
      <div :class="cFluid">
        <div :class="className">
          <nav class="navbar navbar-expand-lg navbar-light">
            <router-link class="navbar-brand" to="/">
              <img
                src="../../assets/images/logos/lstlogo-3.png"
                class="logo-one"
                alt="Logo"
              />
              <img
                src="../../assets/images/logos/logo-2.png"
                class="logo-two"
                alt="Logo"
              />
            </router-link>

            <div
              class="navbar-toggler"
              @click="show = !show"
              :aria-pressed="show ? 'true' : 'false'"
              v-bind:class="{ show: button_show_state }"
              v-on:click="button_show_state = !button_show_state"
            >
              <span class="icon-bar top-bar"></span>
              <span class="icon-bar middle-bar"></span>
              <span class="icon-bar bottom-bar"></span>
            </div>

            <div class="collapse navbar-collapse" :class="{ show: show }">
              <ul class="navbar-nav m-auto">
                <li class="nav-item">
                  <!--<a href="#" class="nav-link">
                    Home
                    <i class="bx bx-caret-down"></i>
                  </a>-->
                  <router-link to="/" class="nav-link">
                    Home
                  </router-link>
                  <!--<ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/" class="nav-link">
                        Home One
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-two" class="nav-link">
                        Home Two
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-three" class="nav-link">
                        Home Three
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-four" class="nav-link">
                        Home Four
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-five" class="nav-link">
                        Home Five
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/home-six" class="nav-link">
                        Home Six
                      </router-link>
                    </li>
                  </ul>-->
                </li>
               <!-- <li class="nav-item">
                  <router-link to="/about" class="nav-link">
                    About
                  </router-link>
                </li>-->      
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    EdTech Services
                    <i class="bx bx-caret-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/CustomDevelopment" class="nav-link" >
                        Custom Learning Solutions
                      </router-link>
                      <!--<a href="/CustomDevelopment" target="_blank" rel="noopener noreferrer">Custom Learning Development 2</a>-->
                    </li>
                    <li class="nav-item">
                      <router-link to="/Animation" class="nav-link" >
                        Animation and Video Services
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/K12Education" class="nav-link" >
                        Educational K12 Solutions
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/LocaleTranslate" class="nav-link" >
                        Localization and Translation Solutions
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/FlashTHTML" class="nav-link">
                        Flash to HTML Conversion
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/StaffAug" class="nav-link">
                        Staff Augmentation
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    IT Services
                    <i class="bx bx-caret-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/AppServices" class="nav-link" > 
                        Application Services
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/WebDevelopment" class="nav-link" >
                        Web Development
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Products
                    <i class="bx bx-caret-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/FireSafety" class="nav-link" >
                        Fire Safety
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/SexHarassment" class="nav-link" >
                        Sexual Harassment
                      </router-link>
                    </li>
                    <!--<li class="nav-item">
                      <router-link to="/authoring" class="nav-link">
                        Authoring Tools
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/gamification" class="nav-link">
                        Gamification
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/video" class="nav-link">
                        Video Practice
                      </router-link>
                    </li>-->
                  </ul>
                </li>
               <!-- <li class="nav-item">
                  <a href="#" class="nav-link">
                    Blog
                    <i class="bx bx-caret-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/blog" class="nav-link">
                        Blog Style One
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/blog-two" class="nav-link">
                        Blog Style Two
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/blog-details" class="nav-link">
                        Blog Details
                      </router-link>
                    </li>
                  </ul>
                </li>-->
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Company
                    <i class="bx bx-caret-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <router-link to="/about" class="nav-link">
                        About Us
                      </router-link>
                      <!--<a href="https://lucreskilltech.com/about" target="_blank">About Us</a>
                      <button @click="$router.push('/about')">Posts</button>-->
                    </li>
                    <li class="nav-item">
                      <router-link to="/team" class="nav-link">
                        Team
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/career" class="nav-link">
                        Careers
                      </router-link>
                    </li>
                    <!--<li class="nav-item">
                      <router-link to="/testimonials" class="nav-link">
                        Testimonials
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/faq" class="nav-link">
                        FAQ
                      </router-link>
                    </li>-->
                    <!--<li class="nav-item">
                      <a href="#" class="nav-link">
                        Case Study
                        <i class="bx bx-caret-down"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li class="nav-item">
                          <router-link to="/case-study" class="nav-link">
                            Case Study
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link
                            to="/case-study-details"
                            class="nav-link"
                          >
                            Case Study Details
                          </router-link>
                        </li>
                      </ul>
                    </li>-->

                    <!--<li class="nav-item">
                      <a href="#" class="nav-link">
                        User
                        <i class="bx bx-caret-down"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li class="nav-item">
                          <router-link to="/log-in" class="nav-link">
                            Log In
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link to="/register" class="nav-link">
                            Registration
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link to="/forget-password" class="nav-link">
                            Forget Password
                          </router-link>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <router-link to="/terms-condition" class="nav-link">
                        Terms & Conditions
                      </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/privacy-policy" class="nav-link">
                        Privacy Policy
                      </router-link>
                    </li>-->

                    <!--<li class="nav-item">
                      <router-link to="/coming-soon" class="nav-link">
                        Coming Soon
                      </router-link>
                    </li>-->
                  </ul>
                </li>
                <li class="nav-item">
                  <router-link to="/contact" class="nav-link">
                    Contact
                  </router-link>
                </li>
              </ul>
            </div>

            <div :class="['nav-side', navSideMt]">
              <div class="nav-side-item">
                <div
                  class="search-box"
                  @click="active = !active"
                  :aria-pressed="active ? 'true' : 'false'"
                  v-bind:class="{ active: button_active_state }"
                  v-on:click="button_active_state = !button_active_state"
                >
                  <i class="bx bx-search"></i>
                </div>
                <div class="search-side-widget">
                  <form class="search-side-form">
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search..."
                    />
                    <button type="submit">
                      <i class="bx bx-search"></i>
                    </button>
                  </form>
                </div>
              </div>
              <!--<div class="nav-side-item">
                <div class="get-btn">
                  <router-link
                    to="/contact"
                    class="default-btn btn-bg-two border-radius-50"
                  >
                    Get A Quote
                    <i class="bx bx-chevron-right"></i>
                  </router-link>
                </div>
              </div>-->
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <!-- Search Overlay -->
  <div class="search-overlay" :class="{ search_overlay_active: active }">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="search-layer"></div>
        <div class="search-layer"></div>
        <div class="search-layer"></div>

        <div class="search-close" @click="active = !active">
          <span class="search-close-line"></span>
          <span class="search-close-line"></span>
        </div>

        <div class="search-form">
          <form>
            <input
              type="text"
              class="input-search"
              placeholder="Search here..."
            />
            <button type="submit"><i class="bx bx-search"></i></button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- End Search Overlay -->
</template>

<script>
export default {
  name: "MainNavbar",
  props: ["cFluid", "className", "navSideMt"],
  data() {
    return {
      isSticky: false,
      show: false,
      button_show_state: false,
      active: false,
      button_active_state: false,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>