<template>
  <div class="case-details-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="case-article">
            <div class="case-article-img">
              <img
                src="../../assets/images/k12-learning.jpg"
                alt="Images"
              />
            </div>
            <div class="case-article-content">
              <h2>K-12 / Smart Class</h2>
              <p>                
                To suit the needs of the changing academic content requirement, we develop Smart Class content and training materials for K-12 students. Our expert coaches and mentors, trained in various faculties design the most intuitive programs to help strengthen the linguistic, logical, mathematical, interpersonal, and naturalistic skills of modern students. We also provide eBook solutions from editing and proofreading to publishing guidance. By harnessing the power of educational tech, we bring a difference to the education of every child and prepare them for a better future.
              </p>

              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Realize the methodologies involved in fire formation.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Familiarize with workplace Inspection & reporting procedures.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Outline the factors involved in the prevention of fire.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Recognize the different types of fire extinguisher & their usage.
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Discuss the classification & causes of fire at the workplace.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Discuss the importance of various fire control mechanisms.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Describes various fire safety devices & their working mechanisms.
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Details the essential requirements of the emergency evacuation plan.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="case-article-content">
              <h2>Higher Education</h2>
              <p>                
                The current market needs of higher education reflects the aspirations of the ‘always online’ generation and calls for flexible, adaptable, and fast learning tools. We help instructors, trainers, and academic mentors to create the best-in-class higher education programs using gamification and engaging techniques. Our program content are developed using multiple formats like cloud-based SAAS model, virtual classrooms, interactive training sessions, quizzes, whiteboard animation videos, instructional videos, app-based learning etc. to enrich their learning experience and add value to every session.</p>
                <div class="row">
                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-rs case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Digital Painting Services
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Animation Character Design
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Animation Character Development
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Character Design Animation
                    </li>
                  </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                  <ul class="case-article-list case-article-ls">
                    <li>
                      <i class="bx bxs-check-circle"></i>Character Development animation
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Cartoon Character Design
                    </li>
                    <li>
                      <i class="bx bxs-check-circle"></i>Cartoon Character Development
                    </li>                    
                  </ul>
                </div>
              </div>
            </div>
           
            <div class="case-play-btn">
              <a
                class="case-play"
                style="cursor: pointer"
                v-on:click="isPopupMethod(isPopup)"
              >
                <i class="bx bx-play"></i>
              </a>
            </div>

            <div class="case-article-another">
              <h2>What Benefit You Will Get</h2>
              <p>
                Lucreskill is often at the forefront of adopting new learning technologies and methodologies, 
                ensuring that your organization benefits from the latest advancements by assuring commitment 
                to keep content current and providing ongoing technical and customer support. We are adaptive 
                in learning techniques to tailor content to individual learner needs, providing a more 
                personalized experience with our cost effective solution compared to traditional in-person 
                training or education. Elearning can lead to increased job satisfaction and learner 
                satisfaction, as it empowers individuals to learn at their own pace and in their preferred 
                style.
              </p>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study1.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="case-article-another-img">
                    <img
                      src="../../assets/images/case-study/case-study5.jpg"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
              <p>
                eLearning offers numerous benefits for K-12 (kindergarten through 12th grade) 
                education. These advantages can enhance the learning experience for students, 
                teachers, and schools along with access to a vast array of educational resources, 
                including online textbooks, interactive simulations, virtual labs, and multimedia 
                content, enriching the learning experience.
              </p>
            </div>

            <div class="case-work-process">
              <h2>Our Working Proccess</h2>
              <p>
                Uniqueness in eLearning content can be achieved through 
                creativity, originality, and customization to meet the 
                needs of specific audience. By focusing on these aspects 
                at each stage of development, we are creating eLearning 
                materials that stand out and effectively engage learners.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <SideBar />
        </div>
      </div>
    </div>
  </div>

  <div class="popup-video" v-if="isPopup">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              class="responsive-iframe"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar";

export default {
  name: "AnimationVideoDev",
  components: {
    SideBar,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>