<template>
  <div class="work-process-area pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span class="sp-color2">Our Working Process</span>
        <h2>How Our Services Will Help You to Grow Your Business</h2>
      </div>

      <div class="row pt-45 justify-content-center">
        <div
          class="col-lg-3 col-sm-6"
          v-for="proces in process"
          :key="proces.id"
        >
          <div class="work-process-card-three">
            <div class="number-title">{{ proces.number }}.</div>
            <h3>{{ proces.title }}</h3>
            <p>{{ proces.shortDesc }}</p>
            <i :class="proces.icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurWorkingProcess",
  data() {
    return {
      process: [
        {
          id: 1,
          icon: "flaticon-project",
          title: "Discovery",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "01",
        },
        {
          id: 2,
          icon: "flaticon-chip",
          title: "Planning",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "02",
        },
        {
          id: 3,
          icon: "flaticon-effective",
          title: "Execute",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "03",
        },
        {
          id: 4,
          icon: "flaticon-bullhorn",
          title: "Deliver",
          shortDesc:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mollis tempor nunc ac sollicitudin Interdum.",
          number: "04",
        },
      ],
    };
  },
};
</script>